import { AxiosInstance } from "axios";

const transformChatbotConfig = (data: { chat_bot: { enabled: boolean; }; }) => {
  return {
    chatbotEnabled: data.chat_bot.enabled,
  };
};

const fetcher =
  (axios: AxiosInstance) =>
  ({ url, params }: { url: string; params?: any }) =>
    axios
      .get(url, {
        params,
      })
      .then((res) => {
        if (res.data.type === "redirect") {
          return res.data; // Handle redirects or similar scenarios as needed
        }

        // Apply any transformations to the chatbot config data here
        return transformChatbotConfig(res.data);
      });

export default fetcher;
