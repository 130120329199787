import useSWR from "swr";
import axios from "helpers/axios";
import defaultSWROptions from "helpers/defaultSWROptions";
import { TopicSummary } from "types/TopicSummary";

import fetcher from "./fetcher";

export const API_PATH = "/api/web/topics";

export interface Props {
  locale: string;
  path?: string;
}

const useTopicSummaries = ({ locale, path }: Props) => {
  const params = {
    locale,
    path,
  };

  const { data, error, isLoading } = useSWR<TopicSummary[]>(
    {
      url: API_PATH,
      params,
    },
    fetcher(axios),
    defaultSWROptions
  );

  return {
    data,
    error,
    loading: isLoading,
  };
};

export type UseTopicSummariesReturnType = ReturnType<typeof useTopicSummaries>;

export default useTopicSummaries;
