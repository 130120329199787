import React, { useMemo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import isExternalLink from "helpers/isExternalLink";
import { useIntl } from "react-intl";
import { ReactComponent as IconArrowRight } from "../../icons/arrow-right.svg";
import ExternalLink from "./assets/external_link.svg";

import styles from "./BubbleLink.module.scss";

type BubbleSize = "small" | "medium" | "large";
type BubbleColor = "primaryColor" | "secondaryColor";

export interface Props {
  path: string;
  text: string;
  size?: BubbleSize;
  bgColor?: BubbleColor;
  className?: string;
  iconAdornment?: boolean;
}

const BubbleLink = ({
  path,
  text,
  size = "medium",
  bgColor = "primaryColor",
  iconAdornment,
  className,
}: Props): JSX.Element => {
  const intl = useIntl();
  const externalLink = useMemo(() => isExternalLink(path), [path]);
  const LinkComponent = useMemo(() => {
    if (externalLink) {
      return ({ children }: { children: React.ReactNode }) => (
        <a
          href={path}
          className={styles.link}
          target="_blank"
          rel="noreferrer"
          title={text}
          data-testid="external-link"
        >
          {children}
        </a>
      );
    }
    return ({ children }: { children: React.ReactNode }) => (
      <Link className={styles.link} to={path} title={text} data-testid="link">
        {children}
      </Link>
    );
  }, [externalLink]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <LinkComponent>
        <div
          className={classNames(styles.content, styles[size], styles[bgColor])}
        >
          {iconAdornment && (
            <img
              src={ExternalLink}
              alt={intl.formatMessage({
                id: "components.bubblelink.external_link",
                defaultMessage: "External link icon",
                description: "Alt text for external link icon",
              })}
              className={styles.externalLink}
            />
          )}
          <p>{text}</p>
          <IconArrowRight />
        </div>
      </LinkComponent>
    </div>
  );
};

export default BubbleLink;
