import removeEmpty from "helpers/removeEmpty";
import { ApiCohortGroup } from "types/api/cohort_group";
import { CohortGroup } from "types/CohortGroup";
import routeHelper from "i18n/routeHelper";

const transformCohortGroup = (apiCohortGroup: ApiCohortGroup): CohortGroup => {
  const data = {
    uid: apiCohortGroup.uid,
    slug: apiCohortGroup.slug,
    date: apiCohortGroup.date,
    name: apiCohortGroup.name,
    bannerUrl: apiCohortGroup.banner_url,
    deepLink: apiCohortGroup.deeplink,
    author: apiCohortGroup.author,
    description: apiCohortGroup.description,
    postCount: apiCohortGroup.post_count,
    userCount: apiCohortGroup.user_count,
    // TODO: This could be replaced with URL if the API returned a locale
    urls: {
      en: `${routeHelper.en.group(apiCohortGroup.uid)}/${apiCohortGroup.slug}`,
      es: `${routeHelper.es.group(apiCohortGroup.uid)}/${apiCohortGroup.slug}`,
    },
  };

  return removeEmpty(data);
};

export default transformCohortGroup;
