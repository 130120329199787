import React, { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from 'react';

interface ConsentStates {
  analyticsConsent: boolean;
}

interface CookieConsentContextType {
  consentStates: ConsentStates;
  setConsentStates: Dispatch<SetStateAction<ConsentStates>>;
}

const CookieConsentContext = createContext<CookieConsentContextType | undefined>(undefined);

export const useConsent = (): CookieConsentContextType => {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error('useConsent must be used within a CookieConsentContextProvider');
  }
  return context;
};

interface CookieConsentContextProviderProps {
  children: ReactNode;
}

export const CookieConsentContextProvider: React.FC<CookieConsentContextProviderProps> = ({ children }) => {
  const [consentStates, setConsentStates] = useState<ConsentStates>({ analyticsConsent: false });

  const value = { consentStates, setConsentStates };

  return <CookieConsentContext.Provider value={value}>{children}</CookieConsentContext.Provider>;
};
