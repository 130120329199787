/* eslint-disable no-empty */
/* eslint-disable consistent-return */
// Modified from: https://typeofnan.dev/using-session-storage-in-react-with-hooks/

import { useState, useEffect } from "react";

const getSessionStorageOrDefault = (key: string, defaultValue: any) => {
  if (typeof window === "undefined") return;

  let stored: string | null | undefined;

  try {
    stored = sessionStorage.getItem(key);
  } catch (error) {}

  if (!stored) {
    return defaultValue;
  }

  return JSON.parse(stored);
};

function useSessionStorage<T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    try {
      if (value) {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {}
  }, [key, value]);

  return [value, setValue];
}

export default useSessionStorage;
