import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import isExternalLink from "helpers/isExternalLink";
import styles from "./ButtonLink.module.scss";

export interface Props {
  to: string;
  children: React.ReactNode;
  className?: string;
  hollow?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  "data-testid"?: string;
  title?: string;
  disabled?: boolean;
}

const ButtonLink = ({
  children,
  className = "",
  hollow,
  to,
  "data-testid": dataTestId,
  onClick,
  title,
  disabled,
}: Props): JSX.Element => {
  // These may not trigger if the link is to a new page.
  // But *should* work if it's a link within the SPA.
  const handleOnClick = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) {
        event.preventDefault();
        return;
      }
      onClick && onClick(event);
    },
    [onClick, disabled]
  );

  const processedClassNames = useMemo(
    () =>
      classNames({
        [styles.buttonLink]: true,
        [styles.hollow]: hollow,
        [styles.disabled]: !!disabled,
        [className]: true,
      }),
    [hollow, className, disabled]
  );

  const LinkComponent = useMemo(() => {
    if (isExternalLink(to)) {
      return () => (
        <a
          href={to}
          className={processedClassNames}
          target="_blank"
          rel="noreferrer"
          title={title}
          onClick={handleOnClick}
          data-testid={dataTestId}
        >
          {children}
        </a>
      );
    }
    return () => (
      <Link
        className={processedClassNames}
        to={to}
        title={title}
        onClick={handleOnClick}
        data-testid={dataTestId}
      >
        {children}
      </Link>
    );
  }, [to]);

  return <LinkComponent />;
};

export default ButtonLink;
