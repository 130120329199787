import React, { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";
import pluginConfig from "./CookieConsentConfig";
import "./CookieConsent.css";
import { useConsent } from "./CookieConsentContext";
import enJson from "./en.json";
import esJson from "./es.json";
import { useIntl } from "react-intl";

const CookieConsentComponent = () => {
  const intl = useIntl();

  const { consentStates, setConsentStates } = useConsent();

  const updateAnalyticsConsent = (newConsent: boolean) => {
    setConsentStates((prevStates) => ({
      ...prevStates,
      analyticsConsent: newConsent,
    }));
  };


  useEffect(() => {
    const locale = intl.locale;

    if (intl.locale === "es") {
      CookieConsent.setLanguage("es", true);
    } else {
      CookieConsent.setLanguage("en", true);
    }

    const modifiedPluginConfig = {
      ...pluginConfig,
      language: {
        default: locale,
        translations: {
          es: esJson,
          en: enJson,
        },
      },
      onConsent: function ({ cookie }) {
        window.dispatchEvent(new CustomEvent("ptupdate"));

        if (CookieConsent.acceptedCategory("analytics")) {
          // Analytics category enabled
          updateAnalyticsConsent(true);
        }
        // Set a flag in localStorage after consent is processed
        if (!localStorage.getItem("consentProcessed")) {
          localStorage.setItem("consentProcessed", "true");
          window.location.reload();
        }
      },
      onChange: () => {
        // Reset the flag on any consent change to enable reload
        localStorage.removeItem("consentProcessed");
        window.location.reload(); // Reload on consent change
      }
    };

    CookieConsent.run(modifiedPluginConfig);
  }, [intl.locale, setConsentStates]);

  return <></>;
};

export default CookieConsentComponent;
