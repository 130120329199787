/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import parse from "html-react-parser";

import Helmet from "./Helmet";
import Config from "../config";

const oEmbedAPI = "https://graph.facebook.com/v9.0/instagram_oembed";

export default class EmbedInstagram extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    params: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { payload: null };
  }

  render() {
    if (!this.state.payload || !this.state.payload.html) return <></>;

    return (
      <div className={classnames("EmbedInstagram", this.props.classnames)}>
        <Helmet>
          <script async src="//platform.instagram.com/en_US/embeds.js" />
        </Helmet>
        {parse(this.state.payload.html)}
      </div>
    );
  }

  componentDidMount() {
    try {
      fetch(this._query(this.props.params))
        .then((response) => response.json())
        .then((data) => this.setState({ payload: data }));
    } catch (error) {
      // eslint-disable-next-line no-undef
      Sentry.captureException(error);
    }
  }

  _query(params = {}) {
    const esc = encodeURIComponent;
    const embed_url = this.props.url.replace(/\/?\?.*/, "");

    const token = `${Config.auth.facebook.app_id}|${Config.auth.facebook.client_token}`;
    const url = `${oEmbedAPI}?url=${esc(
      embed_url
    )}&access_token=${token}&omitscript=true`;

    const queryString = Object.keys(params)
      .filter((k) => esc(params[k]).length > 0)
      .map((k) => `${esc(k)}=${esc(params[k])}`)
      .join("&");

    if (queryString.length === 0) return url;
    return `${url}&${queryString}`;
  }
}
