// @ts-nocheck
import React, { useMemo } from "react";
import { ListItem } from "schema-dts";
import { Helmet } from "react-helmet-async";

import useWindowLocation from "hooks/useWindowLocation";
import Helpers from "helpers";
import Config from "config";
import Breadcrumbs from "helpers/json-ld/Breadcrumbs";

const httpPath = new RegExp("^https?://.*");

export type HrefLangEntry = {
  locale: string;
  url: string;
  default?: boolean;
};

export interface Props {
  children?: React.ReactNode;
  title: string;
  description?: string;
  noindex?: Boolean;
  imageUrl?: string;
  canonical?: string;
  ogType?: string;
  ogTitle?: string;
  itemtype?: string;
  author?: string;
  published_time?: string;
  relativeImgUrl?: string;
  locale?: string;
  hrefLang?: HrefLangEntry[];
  breadcrumbs?: ListItem[];
}

function CustomHelmet({
  children,
  title,
  description,
  noindex,
  imageUrl,
  canonical,
  ogType = "website",
  ogTitle,
  itemtype,
  author,
  published_time,
  relativeImgUrl,
  locale = "en",
  hrefLang,
  breadcrumbs,
}: Props) {
  const windowLocation = useWindowLocation();
  const location = windowLocation();

  const baseUrl = canonical || location.toString();
  const url = baseUrl?.startsWith("http")
    ? baseUrl
    : Helpers.absoluteUrl(location, canonical || location);

  const processedHrefLang = useMemo(() => {
    const currentUrl = canonical || location.href;
    let languageLinks = {
      "x-default": currentUrl,
      [locale]: currentUrl,
    } as {
      [key: string]: string;
    };

    if (hrefLang) {
      languageLinks = hrefLang.reduce(
        (
          result,
          { locale: hrefLangLocale, url: hrefLangUrl, default: hrefLangDefault }
        ) => ({
          ...result,
          [hrefLangLocale]: hrefLangUrl,
          "x-default": hrefLangDefault ? hrefLangUrl : result["x-default"],
        }),
        languageLinks
      );
    }

    return languageLinks;
  }, [hrefLang]);

  const imgUrl = relativeImgUrl
    ? Helpers.absoluteUrl(location, relativeImgUrl)
    : imageUrl || Config.images.fallback(location);

  const robots =
    noindex || Config.noindex
      ? "noindex,nofollow,noarchive,nocache"
      : "index,follow";

  return (
    <Helmet
      htmlAttributes={
        itemtype
          ? {
              itemscope: null,
              itemtype: `https://schema.org/${itemtype}`,
            }
          : undefined
      }
    >
      <title>{title}</title>

      <link
        rel="canonical"
        href={canonical && canonical.match(httpPath) ? canonical : url}
      />

      {[
        "KentledgeRegular.49ef8994.woff2",
        "KentledgeHeavy.f4c760c6.woff2",
        "KentledgeRegularItalic.4b033476.woff2",
      ].map((fontName) => (
        <link
          rel="preload"
          href={`${process.env.PUBLIC_URL}/static/media/${fontName}`}
          crossOrigin="anonymous"
          as="font"
          type="font/woff2"
          key={fontName}
        />
      ))}

      {description && <meta name="description" content={description} />}
      <meta name="robots" content={robots} />
      <meta httpEquiv="Content-Language" content={locale} />

      <meta property="og:url" content={url} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:type" content={ogType} />
      {locale !== "en" && <meta property="og:locale" content={locale} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:site_name" content="Peanut" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@peanut" />
      <meta name="twitter:creator" content="@peanut" />
      <meta name="twitter:title" content={ogTitle || title} />
      <meta name="twitter:image" content={imgUrl} />
      {description && <meta name="twitter:description" content={description} />}
      {Object.entries(processedHrefLang).map(([l, u]) => (
        <link rel="alternate" href={u} hrefLang={l} key={`hrefLang-${l}`} />
      ))}
      {author && <meta property="article:author" content={author} />}
      {published_time && (
        <meta property="article:published_time" content={published_time} />
      )}
      <meta name="apple-itunes-app" content="app-id=1178656034" />
      <meta name="fo-verify" content="f4091056-63bb-4305-8887-deb0797e8b86" />
      {children}
      <script async src="https://hello.myfonts.net/count/40134c" />

      {breadcrumbs ? (
        <script type="application/ld+json">
          {JSON.stringify(Breadcrumbs(breadcrumbs))}
        </script>
      ) : null}
    </Helmet>
  );
}

export default CustomHelmet;
