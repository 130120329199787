import { useContext } from "react";
import { ReferralDataContext } from "./ReferralDataProvider";

export interface Props {}

export type ReferralQueryData = {
  _branch_match_id?: string;
  _branch_referrer?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
};

const nullToUndefined = (value: string | null | undefined) =>
  value === null ? undefined : value;

const useReferralData = () => {
  const { data } = useContext(ReferralDataContext);

  const queryData: ReferralQueryData = {
    _branch_match_id: nullToUndefined(data.branchMatchId),
    _branch_referrer: nullToUndefined(data.branchReferrer),
    utm_source: nullToUndefined(data.utmSource),
    utm_medium: nullToUndefined(data.utmMedium),
    utm_campaign: nullToUndefined(data.utmCampaign)
  };

  const isReferredUser = Object.values(queryData).reduce((acc, cur) => {
    if (cur) return true;
    return acc;
  }, false);

  const applyReferralData = (url: string) => {
    const processedUrl = new URL(url);

    const urlData = Object.fromEntries(
      new URLSearchParams(processedUrl.search)
    );

    processedUrl.search = new URLSearchParams({
      ...urlData,
      ...queryData
    }).toString();

    return processedUrl.toString();
  };

  return { data, queryData, isReferredUser, applyReferralData };
};

export default useReferralData;
