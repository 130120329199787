/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/static-property-placement */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class EmbedIssuu extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.string,
  };

  render() {
    return (
      <div className={classnames("EmbedIssuu", this.props.className)}>
        <iframe
          title="Issuu embed"
          src={this.props.url}
          style={this._style()}
          allowFullScreen
        />
      </div>
    );
  }

  _style() {
    // eslint-disable-next-line prefer-const
    let styles = {
      width: "100%",
      height: "500px",
      border: "none",
    };
    if (this.props.style.length === 0) return styles;

    this.props.style.split(",").forEach((x) => {
      const arr = x.match(/(.*)=(.*)/);
      if (!arr || arr.size < 3 || !styles[arr[1]]) return;
      styles[arr[1]] = arr[2];
    });

    return styles;
  }
}
