import React from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { useIntl } from "react-intl";

import {
  goalFooterIosClick,
  goalFooterAndroidClick,
  goalFooterDownloadClick
} from "helpers/goalTracking";

import routes from "i18n/routeHelper";
import FormattedMessage from "helpers/formattedMessage";
import BranchLink from "components/BranchLink";
import IconApple from "./assets/apple.svg";
import IconAndroid from "./assets/robot.svg";
import IconFacebook from "./assets/facebook.svg";
import imageTikTok from "./assets/tiktok.svg";
import IconTwitter from "./assets/twitter.svg";
import IconPinterest from "./assets/pinterest.svg";
import IconInstagram from "./assets/instagram.svg";
import * as CookieConsent from "vanilla-cookieconsent";

import Config from "../../config";

import styles from "./PageFooter.module.scss";

const Button: any = loadable(
  () =>
    import(/* webpackChunkName: "components-Button"              */ "../Button")
);

interface Props {
  includeLinks?: boolean;
}

const PageFooter = ({ includeLinks = true }: Props) => {
  const intl = useIntl();
  const routesMap = routes[intl.locale];

  return (
    <div className={styles.wrapper} data-testid="page-footer">
      <footer className={styles.container}>
        <section className={styles.install}>
          <BranchLink platform="ios" onClick={goalFooterIosClick}>
            <Button className={styles.button}>
              <img
                fetchpriority="low"
                loading="lazy"
                src={IconApple}
                alt={intl.formatMessage({
                  id: "generic.download_on_the_app_store",
                  defaultMessage: "Download on the App Store",
                  description: "iOS Download button alt text"
                })}
                className={styles.buttonIOS}
              />
            </Button>
          </BranchLink>
          <BranchLink platform="android" onClick={goalFooterAndroidClick}>
            <Button className={styles.button}>
              <img
                fetchpriority="low"
                loading="lazy"
                src={IconAndroid}
                alt={intl.formatMessage({
                  id: "generic.download_on_the_play_store",
                  defaultMessage: "Download on the Play Store",
                  description: "Android Download button alt text"
                })}
                className={styles.buttonAndroid}
              />
            </Button>
          </BranchLink>
        </section>

        <section className={styles.social}>
          <ul>
            <li>
              <a
                href={intl.formatMessage({
                  id: "social.instagram.url",
                  defaultMessage: "https://www.instagram.com/peanut/",
                  description: "URL of Peanut's Instagram Page"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  fetchpriority="low"
                  loading="lazy"
                  src={IconInstagram}
                  alt="Instagram"
                  width="22"
                  height="22"
                />
              </a>
            </li>
            <li>
              <a
                href={intl.formatMessage({
                  id: "social.facebook.url",
                  defaultMessage: "https://www.facebook.com/PeanutAppOfficial/",
                  description: "URL of Peanut's Facebook Page"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={IconFacebook}
                  alt="Facebook"
                  width="22"
                  height="22"
                  fetchpriority="low"
                  loading="lazy"
                />
              </a>
            </li>

            <li>
              <a
                href={intl.formatMessage({
                  id: "social.twitter.url",
                  defaultMessage: "https://twitter.com/peanut",
                  description: "URL of Peanut's Twitter Page"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={IconTwitter}
                  alt="Twitter"
                  width="27"
                  height="22"
                  fetchpriority="low"
                  loading="lazy"
                />
              </a>
            </li>
            <li>
              <a
                href={intl.formatMessage({
                  id: "social.tiktok.url",
                  defaultMessage: "https://www.tiktok.com/@peanut",
                  description: "URL of Peanut's TikTok Page"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={imageTikTok}
                  alt="TikTok"
                  width="18"
                  height="22"
                  fetchpriority="low"
                  loading="lazy"
                />
              </a>
            </li>
            <li>
              <a
                href={intl.formatMessage({
                  id: "social.pinterest.url",
                  defaultMessage: "https://uk.pinterest.com/peanutapp/",
                  description: "URL of Peanut's Pinterest Page"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  fetchpriority="low"
                  loading="lazy"
                  src={IconPinterest}
                  alt="Pinterest"
                  width="22"
                  height="22"
                />
              </a>
            </li>
          </ul>
        </section>

        {includeLinks && (
          <section className={styles.contacts}>
            <ul>
              <li>
                <FormattedMessage
                  defaultMessage="Advertising enquiries"
                  description="Footer Link: Advertising enquiries"
                  id="components.pagefooter.advertising_enquiries"
                />
                <a href={`mailto:${Config.emails.advertising}`}>
                  <FormattedMessage
                    defaultMessage="advertising@teampeanut.com"
                    description="Advertising email address"
                    id="components.pagefooter.advertising_enquiries_address"
                  />
                </a>
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Press enquiries"
                  description="Footer Link: Press enquiries"
                  id="components.pagefooter.press_enquiries"
                />
                <a href={`mailto:${Config.emails.press}`}>
                  <FormattedMessage
                    defaultMessage="press@teampeanut.com"
                    description="Press email address"
                    id="components.pagefooter.press_enquiries_address"
                  />
                </a>
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Something else?"
                  description="Footer Link: Something Else?"
                  id="components.pagefooter.something_else"
                />
                <a href={`mailto:${Config.emails.contact}`}>
                  <FormattedMessage
                    defaultMessage="info@teampeanut.com"
                    description="General email address"
                    id="components.pagefooter.general_enquiries_address"
                  />
                </a>
              </li>
            </ul>
          </section>
        )}

        {includeLinks && (
          <section className={styles.links}>
            <div>
              <ul>
                <li>
                  <Link to={routesMap.about()}>
                    <FormattedMessage
                      defaultMessage="About us"
                      description="Navigation: About us"
                      id="generic.navigation.aboutus"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={routesMap.topicBlogIndex()}>
                    <FormattedMessage
                      id="generic.navigation.blogindex"
                      defaultMessage="Blog"
                      description="Navigation: Blog"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={routesMap.medicalReview()}>
                    <FormattedMessage
                      id="generic.navigation.medicalreview"
                      defaultMessage="Medical Review"
                      description="Navigation: Medical review"
                    />
                  </Link>
                </li>

                <li>
                  <Link to={routesMap.guidelines()}>
                    <FormattedMessage
                      defaultMessage="Guidelines"
                      description="Navigation: Guidelines"
                      id="generic.navigation.guidelines"
                    />
                  </Link>
                </li>

                {intl.locale === "en" && routesMap.offers() && (
                  <li>
                    <Link to={routesMap.offers()}>
                      <FormattedMessage
                        id="generic.navigation.offers"
                        defaultMessage="Offers"
                        description="Navigation: Offers"
                      />
                    </Link>
                  </li>
                )}
                {intl.locale === "en" && routesMap.peanutAtWork() && (
                  <li>
                    <Link to={routesMap.peanutAtWork()}>
                      <FormattedMessage
                        id="generic.navigation.peanutatwork"
                        defaultMessage="Peanut at Work"
                        description="Navigation: Peanut at Work"
                      />
                    </Link>
                  </li>
                )}
                {routesMap.starther() && (
                  <li>
                    <Link to={routesMap.starther()}>
                      <FormattedMessage
                        id="generic.navigation.starther"
                        defaultMessage="StartHer"
                        description="Navigation: StartHer"
                      />
                    </Link>
                  </li>
                )}

                <li>
                  <a
                    href={routesMap.shop()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="generic.navigation.shop"
                      defaultMessage="Shop"
                      description="Navigation: Shop"
                    />
                  </a>
                </li>

                <li>
                  <Link to={routesMap.peanutNews()}>
                    <FormattedMessage
                      defaultMessage="Peanut News"
                      description="Navigation: From Peanut"
                      id="generic.navigation.peanutnews"
                    />
                  </Link>
                </li>

                <li>
                  <Link to={routesMap.terms()}>
                    <FormattedMessage
                      defaultMessage="Terms"
                      description="Navigation: Terms"
                      id="generic.navigation.terms"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={routesMap.privacy()}>
                    <FormattedMessage
                      defaultMessage="Privacy"
                      description="Navigation: Privacy"
                      id="generic.navigation.privacy"
                    />
                  </Link>
                </li>

                <li>
                  <Link to={routesMap.sitemap()}>
                    <FormattedMessage
                      id="generic.navigation.sitemap"
                      defaultMessage="Sitemap"
                      description="Navigation: Sitemap"
                    />
                  </Link>
                </li>

                <li>
                  <Link to={routesMap.download()} onClick={() => goalFooterDownloadClick()}>
                    <FormattedMessage
                      id="generic.navigation.download"
                      defaultMessage="Download"
                      description="Navigation: Download"
                    />
                  </Link>
                </li>

                <li>
                  <a href={routesMap.dueDateCalculator()}>
                    <FormattedMessage
                      defaultMessage="Due Date Calculator"
                      description="Navigation: Due Date Calculator"
                      id="generic.navigation.duedatecalculator"
                    />
                  </a>
                </li>

                <li>
                  <a href={routesMap.chineseGenderPredictor()}>
                    <FormattedMessage
                      defaultMessage="Chinese Gender Predictor"
                      description="Navigation: Chinese Gender Predictor"
                      id="generic.navigation.chinesegenderpredictor"
                    />
                  </a>
                </li>

                <li>
                  <a href={routesMap.ovulationCalculator()}>
                    <FormattedMessage
                      defaultMessage="Ovulation Calculator"
                      description="Navigation: Ovulation Calculator"
                      id="generic.navigation.ovulationcalculator"
                    />
                  </a>
                </li>

                <li>&nbsp;</li>
              </ul>
            </div>
          </section>
        )}

        <section className={styles.language}>
          <ul>
            <li>
              <Link
                to="/"
                onClick={() => CookieConsent.setLanguage('en', true)}
                className={intl.locale === "en" ? styles.selected : undefined}
              >
                <FormattedMessage
                  defaultMessage="English"
                  description="Navigation: Switch to English"
                  id="generic.navigation.language.english"
                />
              </Link>
            </li>
            <li>
              <Link
                to="/es"
                onClick={() => CookieConsent.setLanguage('es', true)}
                className={intl.locale === "es" ? styles.selected : undefined}
              >
                <FormattedMessage
                  defaultMessage="Spanish"
                  description="Navigation: Switch to Spanish"
                  id="generic.navigation.language.spanish"
                />
              </Link>
            </li>
          </ul>
        </section>

        <section className={styles.copyright}>
          <div>&copy; {new Date().getFullYear()} Peanut</div>
        </section>
      </footer>
    </div>
  );
};

export default PageFooter;
