/* eslint-disable @typescript-eslint/no-unused-vars */
import { I18nContext } from "i18n/I18nProvider";
import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

import styles from "./LocaleLayout.module.scss";

type RouteParams = {
  locale: string;
};

export interface Props {}

function LocaleLayout(): JSX.Element {
  const { pathname } = useLocation();
  const i18n = useContext(I18nContext);

  useEffect(() => {
    const [, locale] = pathname.toLowerCase().split("/");

    if (["en", "es"].includes(locale)) {
      i18n.setLocale(locale);
    } else {
      i18n.setLocale("en");
    }
  }, [pathname]);

  useEffect(() => {
    typeof window !== undefined && window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.wrapper}>
      <Outlet />
    </div>
  );
}

export default LocaleLayout;
