/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-multi-assign */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import parse, { domToReact } from "html-react-parser";
import loadable from "@loadable/component";
import { AspectRatio } from "react-aspect-ratio";

import UnsafeHTML from "dangerously-set-html-content";

import ImageDiv from "./ImageDiv";
import LinkCTA from "./LinkCTA";
import EmbedInstagram from "./EmbedInstagram";
import EmbedYoutube from "./EmbedYoutube";
import EmbedIssuu from "./EmbedIssuu";
import DeepLink from "./DeepLink";

import API from "../api";

import { goalArticleContentsClick, goalArticleArticleLinkClick } from "helpers/goalTracking";

const GoogleAdPlacement = loadable(() =>
  import(
    /* webpackChunkName: "components-GoogleAdPlacement"              */ "./GoogleAdPlacement"
  )
);

const ChineseGenderPredictorWidget = loadable(() =>
  import(
    /* webpackChunkName: "components-ChineseGenderPredictorWidget"              */ "./SEOCalculators/Widgets/ChineseGenderPredictorWidget"
  )
);

const CommunityPostsInsert = loadable(() =>
  import(
    /* webpackChunkName: "components-CommunityPostsInsert"              */ "./Markdown/components/CommunityPostsInsert"
  )
);

const DueDateCalculatorWidget = loadable(() =>
  import(
    /* webpackChunkName: "components-DueDateCalculatorWidget"              */ "./SEOCalculators/Widgets/DueDateCalculatorWidget"
  )
);

const OvulationCalculatorWidget = loadable(() =>
  import(
    /* webpackChunkName: "components-OvulationCalculatorWidget"              */ "./SEOCalculators/Widgets/OvulationCalculatorWidget"
  )
);

const PostCodeWidget = loadable(() =>
  import(
    /* webpackChunkName: "components-PostCodeWidget"              */ "./Markdown/components/PostCodeWidget"
  )
);

const EmbedExpertAdviceCTA = loadable(() =>
  import(
    /* webpackChunkName: "components-ExpertAdviceCTA"              */ "./Markdown/components/ExpertAdviceCTA"
  )
);

const EmbedExpertEndorsementCTA = loadable(() =>
  import(
    /* webpackChunkName: "components-ExpertEndorsement"              */ "./Markdown/components/ExpertEndorsement"
  )
);

const EmbedGroupCTA = loadable(() =>
  import(
    /* webpackChunkName: "components-GroupCTA"              */ "./Markdown/components/GroupCTA"
  )
);

const EmbedBabyNamesCTA = loadable(() =>
  import(
    /* webpackChunkName: "components-BabyNamesCTA"              */ "./Markdown/components/BabyNamesCTA"
  )
);

// TODO: This regex could be better (optional WWW, better capture groups)
const groupUrlRegex = /^https:\/\/www\.peanut-app\.io\/groups\/(.*)--(.*)/;

const expertsUrlRegex = /^https:\/\/www\.peanut-app\.io\/experts\/(.*)/;
const expertsEndorcementRegex = /endorse:(medical|review|authored):(.*)/;

const calculatorRegex = /calculator:(gender|dueDate|ovulation)/;

const locationRegex = /locationWidget:(halfWidth|fullWidth)/;

const instagramUrlRegexp = /^https:\/\/www\.instagram\.com\/p\//;
const youtubeUrlRegexp = /^https:\/\/www\.youtube\.com|^https:\/\/youtu\.be/;
const issuuUrlRegexp = /^\/\/e.issuu.com\/embed\.html\?/;

const babynamesCTARegex = /^#baby-names-insert/;

const imageDimensionRegEx = /(?:o[h|w])=([0-9]*)/g;

export default class Markdown extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    htmlContent: PropTypes.string,
    inserts: PropTypes.any,
    seoTag: PropTypes.object,
    topic: PropTypes.object,
  };

  static defaultProps = {
    inserts: [],
  };

  constructor(props) {
    super(props);
    this.state = { posts: {} };

    this._replacer = this._replacer.bind(this);
    this._firstImage = true;
  }

  _isSimpleContent(content) {
    if (!content) return false;
    if (content.type !== "p") return false;

    if (typeof content.props.children !== "string") return false;

    return true;
  }

  _suitableInsertPositions(content) {
    const positions = content
      ? content.reduce((result, _current, index) => {
        const isSimpleContent = [-1, 0, 1].reduce(
          (simpleContent, relativePosition) => {
            if (simpleContent) {
              simpleContent = this._isSimpleContent(
                content[index + relativePosition]
              );
            }

            return simpleContent;
          },
          true
        );

        if (isSimpleContent) {
          result.push(index + 10);
        }
        return result;
      }, [])
      : [];

    return positions.reduce((result, position) => {
      if (
        !result.includes(position - 1) &&
        !result.includes(position - 2) &&
        !result.includes(position - 3)
      ) {
        result.push(position);
      }
      return result;
    }, []);
  }

  _insert(original, content, index) {
    original[index] = (
      <div key={`injected_${index}`}>
        <div className="Markdown-insert-injected">{content}</div>
        {original[index]}
      </div>
    );

    return original;
  }

  _adPositions(content) {
    const { groups: contentInGroups } = content.reduce(
      ({ groups, groupIndex }, item) => {
        const currentGroup = groups[groupIndex] || [];

        if (["p", "ol"].includes(item.type)) {
          currentGroup.push(item);
          groups[groupIndex] = currentGroup;
        } else {
          groupIndex += 1;
          groups[groupIndex] = [item];
        }

        return { groups, groupIndex };
      },
      { groups: [], groupIndex: 0 }
    );

    const { result } = contentInGroups.reduce(
      ({ result, adCount, adsPlaced }, group) => {
        if (group.length > 3) {
          const groupMiddle = Math.floor(group.length / 2);

          adCount += 1;

          if (adCount % 2 === 0 && adsPlaced < 6) {
            adsPlaced += 1;
            const ad = (
              <GoogleAdPlacement key={`ad_${adsPlaced}`} position="content" />
            );

            const first = group.slice(0, groupMiddle);
            const last = group.slice(groupMiddle, group.length);

            group = [...first, ad, ...last];
          }
        }

        return { result: [...result, ...group], adCount, adsPlaced };
      },
      { result: [], adCount: 0, adsPlaced: 0 }
    );

    return result;
  }

  render() {
    const filteredContent = this._parsed().filter(
      (element) => element !== "\n"
    );

    let parsed = this._suitableInsertPositions(filteredContent).reduce(
      (result, position, index) => {
        if (this.props.inserts[index - 2]) {
          result = this._insert(
            result,
            this.props.inserts[index - 2],
            position
          );
        }
        return result;
      },
      filteredContent
    );

    parsed = this._adPositions(parsed);

    return (
      <div className={classnames("Markdown", this.props.className)}>
        {parsed}
        {this.props.children}
      </div>
    );
  }

  _parsed() {
    const options = {
      replace: (node) => this._replacer(node, options),
    };
    return parse(this.props.htmlContent, options);
  }

  // HTML parser callback, used to detect IDs of stuff to be loaded from the API
  _detector({ posts }, node) {
    if (!node.attribs) return;

    if (node.attribs.class === "Markdown-post-inject") {
      posts.push(node.attribs["data-uid"]);
    }
    if (node.attribs.class === "Markdown-posts-inject") {
      node.attribs["data-uids"].split(",").forEach((uid) => posts.push(uid));
    }
  }

  // HTML parser callback, used to replace special nodes (as identified by their
  // class attribute) with other components as appropriate
  _replacer(node, options) {
    if (!node.attribs) return;

    if (node.name === "script") {
      const args = Object.keys(node.attribs)
        .map((k) => `${k}="${node.attribs[k]}"`)
        .join(" ");

      return (
        <UnsafeHTML
          html={`<script ${args}>${domToReact(node.children)}</script>`}
        />
      );
    }
    if (node.attribs.class === "Markdown-post-inject") {
      const uid = node.attribs["data-uid"];
      return <CommunityPostsInsert uids={[uid]} />;
    }
    if (node.attribs.class === "Markdown-posts-inject") {
      const uids = node.attribs["data-uids"];
      return <CommunityPostsInsert uids={uids.split(",")} />;
    }
    if (node.attribs.class === "Markdown-sidebar") {
      const items = node.children.filter((c) => c.name === "li");
      const left = items[0];
      const right = items[1];

      left.name = right.name = "div";

      return (
        <div className="Markdown-sidebar">
          <div className="Markdown-sidebar-left">
            {domToReact([left], options)}
          </div>
          <div className="Markdown-sidebar-right">
            {domToReact([right], options)}
          </div>
        </div>
      );
    }

    if (node.attribs.class === "Markdown-banner") {
      const img = node.children.find((n) => n.name === "img");
      return (
        <div className="Markdown-banner">
          <ImageDiv className="Markdown-banner-image" src={img.attribs.src} />
          <div className="Markdown-banner-spacer" />
        </div>
      );
    }

    if (node.attribs.class === "Markdown-cta") {
      return (
        <LinkCTA className="Markdown-cta" href={node.attribs.href}>
          {domToReact(node.children, options)}
        </LinkCTA>
      );
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      groupUrlRegex.test(node.attribs.href)
    ) {
      return (
        <EmbedGroupCTA url={node.attribs.href}>
          {node.children?.map((c) => c.data)}
        </EmbedGroupCTA>
      );
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      expertsUrlRegex.test(node.attribs.href)
    ) {
      const topic = node.attribs.href.match(expertsUrlRegex);

      return topic[1] ? (
        <EmbedExpertAdviceCTA topic={topic[1].toLowerCase()}>
          {domToReact(node.children, options)}
        </EmbedExpertAdviceCTA>
      ) : (
        <></>
      );
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      expertsEndorcementRegex.test(node.attribs.href)
    ) {
      const [, type, uid] = node.attribs.href.match(expertsEndorcementRegex);

      return type && uid ? (
        <EmbedExpertEndorsementCTA expertUid={uid} type={type} />
      ) : (
        <></>
      );
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      calculatorRegex.test(node.attribs.href)
    ) {
      const [, calculatorType] = node.attribs.href.match(calculatorRegex);

      switch (calculatorType) {
        case "gender":
          return <ChineseGenderPredictorWidget />;
        case "dueDate":
          return <DueDateCalculatorWidget />;
        case "ovulation":
          return <OvulationCalculatorWidget />;
        default:
          return <></>;
      }
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      locationRegex.test(node.attribs.href)
    ) {
      const [, widgetType] = node.attribs.href.match(locationRegex);

      switch (widgetType) {
        case "fullWidth":
          return <PostCodeWidget fullWidth />;
        case "halfWidth":
          return <PostCodeWidget />;
        default:
          return <></>;
      }
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      babynamesCTARegex.test(node.attribs.href)
    ) {
      return <EmbedBabyNamesCTA />;
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      instagramUrlRegexp.test(node.attribs.href)
    ) {
      return <EmbedInstagram url={node.attribs.href} />;
    }

    if (
      node.attribs.class === "Markdown-embed,nocaption" &&
      instagramUrlRegexp.test(node.attribs.href)
    ) {
      return (
        <EmbedInstagram
          url={node.attribs.href}
          params={{ hidecaption: true }}
        />
      );
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      youtubeUrlRegexp.test(node.attribs.href)
    ) {
      return (
        <EmbedYoutube
          url={node.attribs.href}
          title={domToReact(node.children, options)}
        />
      );
    }

    if (
      node.attribs.class === "Markdown-embed" &&
      issuuUrlRegexp.test(node.attribs.href)
    ) {
      return (
        <EmbedIssuu
          url={node.attribs.href}
          style={domToReact(node.children, options)}
        />
      );
    }

    if (node.attribs.class === "Markdown-faq") {
      const items = node.children.filter((c) => c.name === "li");
      if (items.length !== 2) return;

      const faq_q = items[0].children[0];

      const question = domToReact([faq_q], options);
      const answer = items[1].children.map((child) => {
        if (child.children) {
          const attributes = Object.entries(child.attribs).map(
            ([key, value]) => `${key}="${value}"`
          );
          return parse(
            `<a ${attributes.join(" ")}>${domToReact(
              [child.children[0]],
              options
            )}</a>`
          );
        }
        return domToReact([child], options);
      });

      return (
        <div
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          { }
          <h2 itemProp="name">{question}</h2>
          <div
            itemScope
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
          >
            <div itemProp="text">{answer}</div>
          </div>
        </div>
      );
    }

    if (node.attribs.class === "Markdown-insert") {
      const insertKey = node.attribs["data-key"];

      return (
        <div className="Markdown-insert" key={`insert_key_${insertKey}`}>
          <div className="Markdown-insert-original">{node.children}</div>
          <div className="Markdown-insert-additional">
            {this.props.inserts[insertKey]}
          </div>
        </div>
      );
    }

    if (node.name === "p" && node.children.find((n) => n.name === "img")) {
      if (node.children.length > 1) {
        const textIndex = node.children.findIndex(
          (child) => child.name !== "img"
        );

        const containerClass = classnames("imageContainer", {
          mobileReverse: textIndex === 0,
        });

        return (
          <p className={containerClass}>
            {node?.children?.map((child, index) => {
              if (child?.name === "img") {
                return (
                  <div className="imageElement">
                    {domToReact([child], options)}
                  </div>
                );
              }
              const className =
                index === 0 ? "textElement left" : "textElement right";

              return (
                <div key={index} className={className}>
                  {domToReact([child], options)}
                </div>
              );
            })}
          </p>
        );
      }
      return <p>{domToReact(node.children, options)}</p>;
    }

    if (node.name === "img") {
      const src = node.attribs.src || "";
      let align;
      let size;

      if (src.indexOf("#alignLeft") !== -1) {
        align = "left";
      } else if (src.indexOf("#alignCenter") !== -1) {
        align = "center";
      } else if (src.indexOf("#alignRight") !== -1) {
        align = "right";
      }

      if (src.indexOf("#size6col") !== -1) {
        size = "16.67";
      } else if (src.indexOf("#size4col") !== -1) {
        size = "25";
      } else if (src.indexOf("#size2col") !== -1) {
        size = "50";
      } else if (src.indexOf("#size1col") !== -1) {
        size = "100";
      }

      const imageSrc = (width) => src
        .replace("/750/", `/${width}/`)
        .replace(".jpg", ".webp")
        .replace(".png", ".webp");

      const imageStandard = imageSrc('750');

      const imageSrcLow = imageSrc('25');

      let originalWidth;
      let originalHeight;

      if (imageStandard && imageStandard.match(imageDimensionRegEx)) {
        const dimensions = [...imageStandard.matchAll(imageDimensionRegEx)];
        originalWidth = parseInt(dimensions[0][1]);
        originalHeight = parseInt(dimensions[1][1]);
      }

      let loading = "lazy";

      if (this._firstImage === true) {
        loading = "eager";

        this._firstImage = false;
      }

      return (
        <div
          style={{
            display: "flex",
            justifyContent:
              align === "left"
                ? "flex-start"
                : align === "right"
                  ? "flex-end"
                  : "center",
          }}
        >
          <AspectRatio
            ratio={`${originalWidth}/${originalHeight}`}
            style={{
              width: size ? `${size}%` : "100%",
            }}
          >
            <div
              style={{
                backgroundImage: `url("${imageSrcLow}")`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                width: "100%",
              }}
            >
              <img
                {...node.attribs}
                src={imageStandard}
                srcSet={`${imageSrc('750')} 1x, ${imageSrc('1500')} 2x, ${imageSrc('2250')} 3x`}
                className="Markdown-image"
                loading={loading}
                width="100%"
                height="100%"
              />
            </div>
          </AspectRatio>
        </div>
      );
    }

    if (node.name === "a") {
      const handleClick = (e) => {
        goalArticleArticleLinkClick(node.attribs.href);
        e.preventDefault();
        if (node.attribs.target === '_blank') {
          window.open(node.attribs.href, '_blank');
        } else {
          window.location.href = node.attribs.href;
        }
      };

      if (
        node.attribs.href &&
        /peanut\.(test-)?app\.link/.test(node.attribs.href)
      ) {
        return (
          <DeepLink href={node.attribs.href} onClick={handleClick}>
            {domToReact(node.children, options)}
          </DeepLink>
        );
      }
      const { href } = node.attribs;

      if (href[0] !== "/" && href.indexOf("peanut-app.io") < 0) {
        node.attribs.target = "_blank";
      } else {
        node.attribs.target = "_self";
      }
      return (
        <a {...node.attribs} onClick={handleClick}>
          {domToReact(node.children, options)}
        </a>
      );
    }

    if (node.name === "code") {
      if (node.children.find((n) => n.data === "spacer")) {
        return <div className="Markdown-spacer" />;
      }

      if (node.children.find((n) => n.data === "toc:{n}")) {
        let headers = parse(this.props.htmlContent).filter(
          (n) => n.type === "h2"
        );
        headers = headers.concat(this._getFaqQuestions());

        return (
          <div className="Markdown-toc">
            <ul>
              {headers.map((n, idx) => (
                <li
                  key={`li_${n.key}`}
                  onClick={this._scrollToElement.bind(this, idx)}
                >
                  <span onClick={() => goalArticleContentsClick(this._parseHeader(n))}>{this._parseHeader(n)}</span>
                </li>
              ))}
            </ul>
          </div>
        );
      }
    }
  }

  componentDidMount() {
    if (this.props.htmlContent) {
      const metadata = { posts: [] };
      parse(this.props.htmlContent, {
        replace: this._detector.bind(this, metadata),
      });

      const post_uids = [...new Set(metadata.posts)].join(",");
      if (post_uids) {
        API.posts({ uids: post_uids }).then((data) => {
          const posts = { ...this.state.posts };
          data.forEach((p) => {
            p.comments = [];
            posts[p.uid] = p;
          });
          this.setState({ posts });
        });
      }
    }
  }

  _parseHeader(node, level = 1) {
    if (level > 2) return node.props.children;
    if (node.props.children.type)
      return this._parseHeader(node.props.children, level + 1);
    return node.props.children;
  }

  _getFaqQuestions() {
    if (this.props.htmlContent.indexOf("Markdown-faq") === -1) return [];
    const items = parse(this.props.htmlContent).filter(
      (n) => n.type === "ul" && n.props.class === "Markdown-faq"
    );
    if (items.length === 0) return [];

    return items.map((n) => n.props.children.filter((c) => c.type === "li")[0]);
  }

  _scrollToElement(key) {
    const obj = document.querySelectorAll(".PageSection h2")[key];
    if (obj) obj.scrollIntoView();
  }
}
