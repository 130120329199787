import React, { createContext, useCallback } from "react";

export const LocationContext = createContext<() => Location | URL>(
  () => window.location
);

interface IProps {
  children: React.ReactNode;
  value: Location | URL;
}

// Window.location is not available in SSR mode. This is a workaround.
const WindowLocationProvider = ({ children, value }: IProps) => {
  const typeOfWindow = typeof window;
  const isSSR = typeOfWindow !== "object";

  const location = useCallback(
    () => (isSSR ? value! : window.location),
    [isSSR]
  );

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

export default WindowLocationProvider;
