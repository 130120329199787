import { useContext } from "react";
import { LocationContext } from "./WindowLocationProvider";

// Returns a function to determind the current location.
// This is used to determine the location of the page when using SSR
// and window.location is not available.
// For client side rendering, it uses window.location.href.
const useWindowLocation = (): (() => Location | URL) => {
  const location = useContext(LocationContext);

  return location;
};

export default useWindowLocation;
