/* eslint-disable react/jsx-filename-extension */
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { loadableReady } from "@loadable/component";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
// import TagManager from "react-gtm-module";

import Cookies from "js-cookie";

import I18nProvider from "i18n/I18nProvider";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

import "normalize.css";
import "react-aspect-ratio/aspect-ratio.css";
import "./stylesheets/application.scss";

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID,
//   auth: process.env.REACT_APP_GTM_AUTH,
//   preview: process.env.REACT_APP_GTM_PREVIEW,
// };

// TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  ignoreErrors: [
    "Failed to fetch",
    "TagError Zm(pagead/js/adsbygoogle)",
    "unsupported test mode",
    "pintrk is not defined",
    "TypeError",
    "t.push is not a function",
  ],
  denyUrls: [
    /.*pagead\/js\/adsbygoogle.js.*/i,
    /.*pagead\/managed\/js\/adsense.*/i,
  ],
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.01,
});

const locale = Cookies.get("language") || "en";

loadableReady(() => {
  ReactDOM.hydrate(
    <I18nProvider locale={locale}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </I18nProvider>,
    document.getElementById("root")
  );
});

function sendToAnalytics({ id, name, value }) {
  if (window.ga) {
    window.ga("send", "event", {
      eventCategory: "Web Vitals",
      eventAction: name,
      eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate
    });
  }
}

reportWebVitals(sendToAnalytics);
