/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DeepLink from "./DeepLink";

import { goalMarkdownCTAClick } from "../helpers/goalTracking";

export default class LinkCTA extends React.Component {
  static propTypes = {
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  _onClick() {
    goalMarkdownCTAClick();
  }

  render() {
    return (
      <div className={classnames("LinkCTA", this.props.classnames)}>
        <DeepLink href={this.props.href} onClick={this._onClick} openInNewTab>
          {this.props.children}
        </DeepLink>
      </div>
    );
  }
}
