/* eslint-disable import/no-named-as-default */
import { ApiPod } from "types/api/pods";
import { Pod } from "types/pod";

import removeEmpty from "helpers/removeEmpty";

import transformUserSummary from "./userSummary";
import transformPagesGroup from "./pagesGroup";

const transformPod = (apiPod: ApiPod): Pod => {
  const data = {
    id: apiPod.id,
    deepLink: apiPod.deep_link,
    description: apiPod.description,
    host: transformUserSummary(apiPod.host),
    mode: apiPod.mode,
    participantCount: apiPod.participant_count,
    peanutPick: apiPod.peanut_pick,
    public: apiPod.public,
    scheduledAt: apiPod.scheduled_at,
    slug: apiPod.slug,
    status: apiPod.status,
    tags: apiPod.tags,
    title: apiPod.title,

    group: apiPod.group ? transformPagesGroup(apiPod.group) : undefined,
    featuredParticipants: apiPod.featured_participants
      ? apiPod.featured_participants.map(transformUserSummary)
      : undefined,

    cohosts: apiPod.cohosts
      ? apiPod.cohosts.map(transformUserSummary)
      : undefined,
  } as Pod;

  return removeEmpty(data);
};

export default transformPod;
