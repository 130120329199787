import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";
import { ApiSEOEditorial } from "types/api/seo_editorial";
import { SEOEditorial } from "types/SEOEditorial";

const transformEditorial = (apiEditorial: ApiSEOEditorial): SEOEditorial => {
  const data = objectKeysToCamelCase(apiEditorial);

  data.url =
    data.locale && data.locale !== "en"
      ? `/${data.locale}/${data.slug}`
      : `/${data.slug}`;

  return removeEmpty(data);
};

export default transformEditorial;
