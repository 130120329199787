/* eslint-disable no-restricted-syntax */
/* 
  useBranchLink
  Input: A Branch Link
  Output A branch link with default settings, and retained referral data 

*/
import useReferralData, { ReferralQueryData } from "hooks/useReferralData";
import { useState, useEffect } from "react";

import queryString from "query-string";
import Config from "../../config";
import useBranchSDK from "../useBranchSdk";

const fieldsToCopy = ["~feature", "~channel", "~campaign", "referrer_uid"];

const branchAttributedUrl = (href: string, branchData: any) => {
  const url = new URL(href);

  const parsed = queryString.parse(url.search);

  for (const field of fieldsToCopy) {
    const value = branchData[field];

    if (!!value && value !== "nil") {
      parsed[field] = value;
    }
  }

  url.search = queryString.stringify(parsed, { encode: false, strict: false });

  return url.toString();
};

const referralAttributedUrl = (href: string) => {
  const url = new URL(href);

  if (typeof window === "undefined") return url.toString();

  if (window.document.referrer) {
    const referrerUrl = new URL(window.document.referrer);

    url.searchParams.set("~campaign", referrerUrl.hostname);
    url.searchParams.set("referring_url", referrerUrl.origin);
  }

  return url.toString();
};

// Most, app-generated, links will have a desktop fallback of
// the main site, which isn't much help if you are already on the main site,
// therefore we override the desktop fallback to the download page.
const setDesktopFallback = (
  url: string,
  desktopFallback: string = `${process.env.REACT_APP_BASE_URL}${Config.paths.download}`
) => {
  const processedUrl = new URL(url);
  const query = queryString.parse(processedUrl.search);

  query.$fallback_url = "";
  query.$desktop_fallback_url = desktopFallback;

  processedUrl.search = queryString.stringify(query, {
    encode: false,
    strict: false,
  });
  return processedUrl.toString();
};

const setReferralQueryData = (url: string, queryData: ReferralQueryData) => {
  const processedUrl = new URL(url);
  const query = queryString.parse(processedUrl.search);

  Object.entries(queryData).reduce((result, [key, value]) => {
    const x = result;
    if (value) {
      x[key] = value as string;
    }
    return x;
  }, query);

  processedUrl.search = queryString.stringify(query, {
    encode: false,
    strict: false,
  });
  return processedUrl.toString();
};

const useBranchLink = (
  url: string | undefined = Config.urls.branch.base,
  desktopFallback: string = `${process.env.REACT_APP_BASE_URL}/download`
) => {
  const { queryData: referralQueryData } = useReferralData();
  const { asyncInit } = useBranchSDK();
  const [pending, setPending] = useState<boolean>(false);
  const [finalUrl, setFinalUrl] = useState<string>();
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    if (!pending) return;

    // Initialize branch and get the link data
    const getProcessedUrl = async (href: string) => {
      let branchResponse = response;
      if (!response) {
        branchResponse = await asyncInit();
        setResponse(branchResponse);
      }

      const jsonData = branchResponse.data;

      /* Sample Response:
        {
          "+match_guaranteed": true,
          $desktop_url: "http://local-dev.peanut-app.io:3000/blog/mexican-baby-girl-names",
          $marketing_title: "TESTING - LOCALHOST",
          "~creation_source": 1,
          "~marketing": true,
          "+clicked_branch_link": true,
          "+click_timestamp": 1658932194,
          $one_time_use: false,
          "~id": 1080836420278894089,
          "+is_first_session": false,
          "~referring_link": "https://peanut.app.link/localhost",
          "~feature": "marketing",
        };
      */

      let processedUrl =
        jsonData && jsonData["+clicked_branch_link"] === true
          ? branchAttributedUrl(href, jsonData) // User came from a branch link
          : referralAttributedUrl(href); // User came via other means

      // // Override the desktop fallback to the download page.
      processedUrl = setDesktopFallback(processedUrl, desktopFallback);

      processedUrl = setReferralQueryData(processedUrl, referralQueryData);

      setFinalUrl(processedUrl);
      setPending(false);
    };

    if (url) {
      getProcessedUrl(url);
    } else {
      setFinalUrl(undefined);
    }
  }, [url, pending]);

  const fetchUrl = () => {
    setPending(true);
  };

  return { url: finalUrl, fetchUrl };
};

export default useBranchLink;
