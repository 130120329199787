/*
  BranchLink - Is a DeepLink with certain default set (~feature, ~channel, ~campaign)
  In: Nothing
  Out: Branch Link
*/
import React, { useCallback, useState, useEffect } from "react";

import classnames from "classnames";

import useBranchLink from "hooks/useBranchLink";
import { useIntl } from "react-intl";
import DeepLink from "../DeepLink";

export interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  platform?: "ios" | "android" | "default";
}

// Note: Brach auto redirects to the app store on mobile
const BASE_LINKS: { [key: string]: { [key: string]: string } } = {
  en: {
    ios: "https://peanut.app.link/ios/", // Desktop goes to App Store
    android: "https://peanut.app.link/android/", // Desktop goes to Play Store
    default: "https://peanut.app.link/default", // Desktop goes to /download/
  },
  es: {
    ios: "https://peanut.app.link/ios-es/", // Desktop goes to App Store
    android: "https://peanut.app.link/android-es/", // Desktop goes to Play Store
    default: "https://peanut.app.link/default-es", // Desktop goes to /download/
  },
};

function BranchLink({
  className,
  onClick,
  children,
  platform = "default",
}: Props) {
  const intl = useIntl();

  const LINKS = BASE_LINKS[intl.locale] || BASE_LINKS.en;

  const defaultUrl = new URL(LINKS[platform]).toString();

  const { url: branchUrl, fetchUrl } = useBranchLink(defaultUrl);
  const [processing, setProcessing] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (processing && branchUrl) {
      setProcessing(false);

      window.location.href = branchUrl;
    }
  }, [branchUrl]);

  useEffect(() => {
    if (failed) {
      if (!branchUrl) {
        window.location.href = defaultUrl;
      }
      setProcessing(false);
      setFailed(false);
    }
  }, [failed]);

  const handleClick = useCallback(() => {
    setTimeout(() => setFailed(true), 1000);
    setProcessing(true);
    fetchUrl();
    onClick && onClick();
  }, [onClick]);

  return (
    <DeepLink
      href={branchUrl || defaultUrl}
      className={classnames("BranchLink", className)}
      onClick={handleClick}
    >
      {children}
    </DeepLink>
  );
}

export default BranchLink;
