import { transformTopicSummary } from "helpers/dataTransformation";
import { AxiosInstance } from "axios";
import { TopicSummary } from "types/TopicSummary";

const fetcher =
  (axios: AxiosInstance) =>
  ({ url, params }: { url: string; params: any }) =>
    axios
      .get(url, {
        params,
      })
      .then((res) => res.data.map(transformTopicSummary) as TopicSummary[]);

export default fetcher;
