/** ** DO NOT EDIT ****
 * This file is generated by ./bin/update-i18n.ts
 * Any changes made here will be overwritten
 * Run ./bin/update-i18n.ts to update
 ** ** DO NOT EDIT *** */
import React from "react";
import loadable from "@loadable/component";
import { Route } from "react-router-dom";

import LocaleLayout from "layouts/LocaleLayout";

const AboutUs: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-AboutUs"              */ "../../../pages/AboutUs"
    )
);

const BlogIndex: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-BlogIndex"              */ "../../../pages/BlogIndex"
    )
);

const ChildcareChoices: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-ChildcareChoices"              */ "../../../pages/ChildcareChoices"
    )
);

const CommunityIndex: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-CommunityIndex"              */ "../../../pages/CommunityIndex"
    )
);

const BlogPage: any = loadable(
  () => import(/* webpackChunkName: "page-SEOBlog"  */ "../../../pages/SEOBlog")
);

const AuthorPage: any = loadable(
  () =>
    import(/* webpackChunkName: "page-SEOAuthor"  */ "../../../pages/SEOAuthor")
);

const CommunityPost: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-CommunityPost"              */ "../../../pages/CommunityPost"
    )
);

const DefaultLayout: any = loadable(
  () =>
    import(
      /* webpackChunkName: "layouts-DefaultLayout"  */ "../../../layouts/DefaultLayout"
    )
);

const DueDateCalculatorPage: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-DueDateCalculatorPage"  */ "../../../pages/SEOCalculators/DueDateCalculator/DueDateCalculatorPage/DueDateCalculatorPage"
    )
);

const DueDateCalculatorResultsPage: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-DueDateCalculatorResultsPage"  */ "../../../pages/SEOCalculators/DueDateCalculator/DueDateCalculatorResultsPage/DueDateCalculatorResultsPage"
    )
);

const Download: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-Download"           */ "../../../pages/Download"
    )
);

const Experts: any = loadable(
  () => import(/* webpackChunkName: "page-Experts"  */ "../../../pages/Experts")
);

const FertilityCommunity: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-FertilityCommunity"         */ "../../../pages/LifestageLandingPages/Fertility"
    )
);

const GenderCalculatorPage: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-GenderCalculatorPage"  */ "../../../pages/SEOCalculators/ChineseGenderPredictor/GenderCalculatorPage/GenderCalculatorPage"
    )
);

const GenderCalculatorResultsPage: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-GenderCalculatorResultsPage"  */ "../../../pages/SEOCalculators/ChineseGenderPredictor/GenderCalculatorResultsPage/GenderCalculatorResultsPage"
    )
);

const Group: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-Group"         */ "../../../pages/PagesGroup"
    )
);

const Guidelines: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-Guidelines"         */ "../../../pages/Guidelines"
    )
);

const Home: any = loadable(
  () =>
    import(/* webpackChunkName: "page-Home"         */ "../../../pages/Home")
);

const LandingPage: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-LandingPage"         */ "../../../pages/LandingPage"
    )
);

const MedicalReview: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-MedicalReview"         */ "../../../pages/MedicalReview"
    )
);

const MenopauseCommunity: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-MenopauseCommunity"         */ "../../../pages/LifestageLandingPages/Menopause"
    )
);

const MotherhoodCommunity: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-MotherhoodCommunity"         */ "../../../pages/LifestageLandingPages/Motherhood"
    )
);

const NotFound: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-NotFound"         */ "../../../pages/NotFound"
    )
);

const Offers: any = loadable(
  () => import(/* webpackChunkName: "page-Offers"  */ "../../../pages/Offers")
);

const OvulationCalculatorPage: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-OvulationCalculatorPage"  */ "../../../pages/SEOCalculators/OvulationCalculator/OvulationCalculatorPage/OvulationCalculatorPage"
    )
);

const OvulationCalculatorResultsPage: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-OvulationCalculatorResultsPage"  */ "../../../pages/SEOCalculators/OvulationCalculator/OvulationCalculatorResultsPage/OvulationCalculatorResultsPage"
    )
);

const PeanutAtWork: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-PeanutAtWork"         */ "../../../pages/PeanutAtWork"
    )
);

const Pod: any = loadable(
  () => import(/* webpackChunkName: "page-Pod"         */ "../../../pages/Pod")
);

const PregnancyCommunity: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-PregnancyCommunity"         */ "../../../pages/LifestageLandingPages/Pregnancy"
    )
);

const Privacy: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-Privacy"         */ "../../../pages/Privacy"
    )
);

const RedirectGroups: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-RedirectGroups"  */ "../../../redirects/PagesGroup"
    )
);

const Editorial: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-Editorial"  */ "../../../pages/SEOEditorial"
    )
);

const Sitemap: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-Sitemap"         */ "../../../pages/Sitemap"
    )
);

const SMSFallback: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-SMSFallback"         */ "../../../pages/SMSFallback"
    )
);

const StartHer: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-StartHer"         */ "../../../pages/StartHer"
    )
);

const Terms: any = loadable(
  () =>
    import(/* webpackChunkName: "page-Terms"         */ "../../../pages/Terms")
);

const TopicCommunityIndex: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-TopicCommunityIndex"         */ "../../../pages/TopicCommunityIndex"
    )
);

const TopicBlogIndex: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-TopicBlogIndex"         */ "../../../pages/TopicBlogIndex"
    )
);

const TopicHub: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-TopicHub"         */ "../../../pages/TopicHub"
    )
);

const TopicLayout: any = loadable(
  () =>
    import(
      /* webpackChunkName: "layout-TopicLayout"         */ "../../../layouts/TopicLayout"
    )
);

const Ttc: any = loadable(
  () =>
    import(
      /* webpackChunkName: "page-TTC"                */ "../../../pages/Ttc"
    )
);

function i18nRoutes() {
  return (
    <Route key="routes-en" path="" element={<LocaleLayout />}>
      {/* http://peanut-app.io/about-us */}
      <Route path="about-us" element={<AboutUs />} />

      {/* http://peanut-app.io/posts/29gpx-icd/this-is-my-post */}
      <Route path="posts/:uid/:slug" element={<CommunityPost />} />

      {/* DEPRICIATED - http://peanut-app.io/posts/29gpx-icd */}
      <Route path="posts/:uid" element={<CommunityPost />} />

      {/* http://peanut-app.io/fertility-community */}
      <Route path="fertility-community" element={<FertilityCommunity />} />

      {/* http://peanut-app.io/experts/:slug */}
      <Route path="experts/:slug" element={<Experts />} />

      {/* http://peanut-app.io/experts */}
      <Route path="experts" element={<Experts />} />

      {/* http://peanut-app.io/groups/ji7-3jh/group-name-slug */}
      <Route path="groups/:id/:slug" element={<Group />} />

      {/* http://peanut-app.io/groups/ji7-3jh/ */}
      <Route path="groups/:id" element={<RedirectGroups />} />

      <Route path="groups/:slug--:id" element={<RedirectGroups />} />

      {/* http://peanut-app.io/guidelines */}
      <Route path="community-guidelines" element={<Guidelines />} />

      {/* http://peanut-app.io/l/:slug */}
      <Route path="l/:slug" element={<LandingPage />} />

      {/* http://peanut-app.io/offers */}
      <Route path="editorial">
        <Route path="peanut-offers" element={<Offers />} />
      </Route>
      {/* http://peanut-app.io/menopause-community */}
      <Route path="menopause-community" element={<MenopauseCommunity />} />

      {/* http://peanut-app.io/motherhood-community */}
      <Route path="motherhood-community" element={<MotherhoodCommunity />} />

      {/* http://peanut-app.io/pregnancy-community */}
      <Route path="pregnancy-community" element={<PregnancyCommunity />} />
      {/* http://peanut-app.io/ttc */}
      <Route path="ttc" element={<Ttc />} />

      {/* http://peanut-app.io/privacy */}
      <Route path="privacy" element={<Privacy />} />

      {/* http://peanut-app.io/sitemap */}
      <Route path="sitemap" element={<Sitemap />} />

      {/* http://peanut-app.io/starther */}
      <Route path="starther" element={<StartHer />} />

      {/* http://peanut-app.io/terms */}
      <Route path="terms" element={<Terms />} />

      <Route path="pods/:id/:slug" element={<Pod />} />

      <Route path="404" element={<NotFound status={404} />} />
      <Route path="503" element={<NotFound status={503} />} />
      {/* <Route path=":parentSlug/:slug" element={<Editorial />} />
        <Route path=":slug" element={<Editorial />} /> */}
      <Route path="_sms" element={<SMSFallback />} />

      <Route element={<DefaultLayout />}>
        <Route index element={<Home />} />
        {/* http://peanut-app.io/blog */}
        <Route path="blog">
          <Route path=":slug" element={<BlogPage />} />
          <Route index element={<BlogIndex />} />
        </Route>

        {/* http://peanut-app.io/posts/ */}
        <Route path="posts" element={<CommunityIndex />} />

        {/* http://peanut-app.io/download */}
        <Route path="download" element={<Download />} />
        <Route path="medical-review">
          <Route index element={<MedicalReview />} />
        </Route>
        {/* http://peanut-app.io/peanut-at-work */}
        <Route path="peanut-at-work" element={<PeanutAtWork />} />
        <Route path="author">
          {/* http://peanut-app.io/author/authorName */}
          <Route path=":slug" element={<AuthorPage />} />
        </Route>
        <Route
          path="due-date-calculator/results/:date"
          element={<DueDateCalculatorResultsPage />}
        />
        <Route path="due-date-calculator" element={<DueDateCalculatorPage />} />

        <Route
          path="chinese-gender-predictor/results/:gender"
          element={<GenderCalculatorResultsPage />}
        />
        <Route
          path="chinese-gender-predictor"
          element={<GenderCalculatorPage />}
        />

        <Route
          path="ovulation-calculator/results/:cycle/:firstCycleDate/"
          element={<OvulationCalculatorResultsPage />}
        />
        <Route
          path="ovulation-calculator"
          element={<OvulationCalculatorPage />}
        />

        <Route path="/planning-for-a-baby" element={<TopicLayout id={44} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={44} />} />
          <Route path="blog" element={<TopicBlogIndex id={44} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={44} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={44} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/planning-for-a-baby/fertility-treatments"
          element={<TopicLayout id={45} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={45} />} />
          <Route path="blog" element={<TopicBlogIndex id={45} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={45} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={45} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/planning-for-a-baby/trying-to-conceive"
          element={<TopicLayout id={46} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={46} />} />
          <Route path="blog" element={<TopicBlogIndex id={46} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={46} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={46} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/planning-for-a-baby/pregnancy-tests"
          element={<TopicLayout id={47} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={47} />} />
          <Route path="blog" element={<TopicBlogIndex id={47} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={47} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={47} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/planning-for-a-baby/ovulation"
          element={<TopicLayout id={48} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={48} />} />
          <Route path="blog" element={<TopicBlogIndex id={48} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={48} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={48} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/planning-for-a-baby/adoption-fostering-surrogacy"
          element={<TopicLayout id={49} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={49} />} />
          <Route path="blog" element={<TopicBlogIndex id={49} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={49} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={49} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/pregnancy" element={<TopicLayout id={50} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={50} />} />
          <Route path="blog" element={<TopicBlogIndex id={50} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={50} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={50} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy/pregnancy-symptoms"
          element={<TopicLayout id={51} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={51} />} />
          <Route path="blog" element={<TopicBlogIndex id={51} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={51} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={51} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy/pregnancy-scans"
          element={<TopicLayout id={52} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={52} />} />
          <Route path="blog" element={<TopicBlogIndex id={52} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={52} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={52} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy/pregnancy-care"
          element={<TopicLayout id={53} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={53} />} />
          <Route path="blog" element={<TopicBlogIndex id={53} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={53} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={53} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy/health-and-nutrition"
          element={<TopicLayout id={54} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={54} />} />
          <Route path="blog" element={<TopicBlogIndex id={54} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={54} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={54} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/pregnancy/baby-showers" element={<TopicLayout id={55} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={55} />} />
          <Route path="blog" element={<TopicBlogIndex id={55} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={55} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={55} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy/pregnancy-loss"
          element={<TopicLayout id={56} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={56} />} />
          <Route path="blog" element={<TopicBlogIndex id={56} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={56} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={56} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/pregnancy/baby-names" element={<TopicLayout id={57} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={57} />} />
          <Route path="blog" element={<TopicBlogIndex id={57} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={57} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={57} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy/labor-and-birth"
          element={<TopicLayout id={58} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={58} />} />
          <Route path="blog" element={<TopicBlogIndex id={58} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={58} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={58} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/baby" element={<TopicLayout id={59} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={59} />} />
          <Route path="blog" element={<TopicBlogIndex id={59} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={59} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={59} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/baby/baby-feeding" element={<TopicLayout id={60} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={60} />} />
          <Route path="blog" element={<TopicBlogIndex id={60} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={60} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={60} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/baby/baby-care" element={<TopicLayout id={61} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={61} />} />
          <Route path="blog" element={<TopicBlogIndex id={61} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={61} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={61} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/baby/baby-milestones" element={<TopicLayout id={62} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={62} />} />
          <Route path="blog" element={<TopicBlogIndex id={62} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={62} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={62} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/baby/breastfeeding" element={<TopicLayout id={63} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={63} />} />
          <Route path="blog" element={<TopicBlogIndex id={63} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={63} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={63} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/baby/baby-sleep" element={<TopicLayout id={64} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={64} />} />
          <Route path="blog" element={<TopicBlogIndex id={64} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={64} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={64} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/baby/baby-products" element={<TopicLayout id={65} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={65} />} />
          <Route path="blog" element={<TopicBlogIndex id={65} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={65} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={65} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/baby/postpartum-care" element={<TopicLayout id={66} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={66} />} />
          <Route path="blog" element={<TopicBlogIndex id={66} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={66} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={66} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/toddler-and-child" element={<TopicLayout id={67} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={67} />} />
          <Route path="blog" element={<TopicBlogIndex id={67} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={67} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={67} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/toddler-and-child/health-and-nutrition"
          element={<TopicLayout id={68} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={68} />} />
          <Route path="blog" element={<TopicBlogIndex id={68} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={68} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={68} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/toddler-and-child/activities"
          element={<TopicLayout id={69} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={69} />} />
          <Route path="blog" element={<TopicBlogIndex id={69} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={69} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={69} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/toddler-and-child/childcare-and-education"
          element={<TopicLayout id={70} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={70} />} />
          <Route path="blog" element={<TopicBlogIndex id={70} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={70} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={70} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/toddler-and-child/parenting"
          element={<TopicLayout id={71} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={71} />} />
          <Route path="blog" element={<TopicBlogIndex id={71} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={71} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={71} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/toddler-and-child/child-development"
          element={<TopicLayout id={72} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={72} />} />
          <Route path="blog" element={<TopicBlogIndex id={72} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={72} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={72} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/health-and-wellbeing" element={<TopicLayout id={73} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={73} />} />
          <Route path="blog" element={<TopicBlogIndex id={73} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={73} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={73} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/health-and-wellbeing/nutrition-and-fitness"
          element={<TopicLayout id={74} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={74} />} />
          <Route path="blog" element={<TopicBlogIndex id={74} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={74} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={74} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/health-and-wellbeing/womens-health"
          element={<TopicLayout id={75} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={75} />} />
          <Route path="blog" element={<TopicBlogIndex id={75} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={75} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={75} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/health-and-wellbeing/hair-and-beauty"
          element={<TopicLayout id={76} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={76} />} />
          <Route path="blog" element={<TopicBlogIndex id={76} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={76} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={76} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/health-and-wellbeing/mental-health"
          element={<TopicLayout id={77} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={77} />} />
          <Route path="blog" element={<TopicBlogIndex id={77} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={77} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={77} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/health-and-wellbeing/sex-and-relationships"
          element={<TopicLayout id={78} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={78} />} />
          <Route path="blog" element={<TopicBlogIndex id={78} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={78} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={78} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/health-and-wellbeing/work-and-money"
          element={<TopicLayout id={79} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={79} />} />
          <Route path="blog" element={<TopicBlogIndex id={79} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={79} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={79} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/health-and-wellbeing/shopping-and-home"
          element={<TopicLayout id={80} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={80} />} />
          <Route path="blog" element={<TopicBlogIndex id={80} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={80} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={80} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/menopause" element={<TopicLayout id={81} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={81} />} />
          <Route path="blog" element={<TopicBlogIndex id={81} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={81} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={81} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route path="/from-peanut" element={<TopicLayout id={82} />}>
          <Route path="blog/page/:page" element={<TopicBlogIndex id={82} />} />
          <Route path="blog" element={<TopicBlogIndex id={82} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={82} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={82} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy/pregnancy-week-by-week"
          element={<TopicLayout id={83} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={50} />} />
          <Route path="blog" element={<TopicBlogIndex id={50} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={50} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={50} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy-week-by-week/first-trimester"
          element={<TopicLayout id={84} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={84} />} />
          <Route path="blog" element={<TopicBlogIndex id={84} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={84} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={84} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy-week-by-week/second-trimester"
          element={<TopicLayout id={85} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={85} />} />
          <Route path="blog" element={<TopicBlogIndex id={85} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={85} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={85} />} />
          <Route index element={<TopicHub />} />
        </Route>

        <Route
          path="/pregnancy-week-by-week/third-trimester"
          element={<TopicLayout id={86} />}
        >
          <Route path="blog/page/:page" element={<TopicBlogIndex id={86} />} />
          <Route path="blog" element={<TopicBlogIndex id={86} />} />
          <Route
            path="community/page/:page"
            element={<TopicCommunityIndex id={86} />}
          />
          <Route path="community" element={<TopicCommunityIndex id={86} />} />
          <Route index element={<TopicHub />} />
        </Route>
      </Route>

      <Route path="editorial">
        <Route path=":parentSlug/:slug" element={<Editorial />} />
        <Route path=":slug" element={<Editorial />} />
      </Route>
      <Route path="/*" element={<NotFound status={404} />} />
      <Route path="*" element={<NotFound status={404} />} />
    </Route>
  );
}

export default i18nRoutes;
/** ** DO NOT EDIT ****
 * This file is generated by ./bin/update-i18n.ts
 * Any changes made here will be overwritten
 * Run ./bin/update-i18n.ts to update
 ** ** DO NOT EDIT *** */
