import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";
import { ApiSEOBlogSummary } from "types/api/seo_blog_summary";
import { SEOBlogSummary } from "types/SEOBlogSummary";

const transformBlogSummary = (apiBlog: ApiSEOBlogSummary): SEOBlogSummary => {
  const data = objectKeysToCamelCase(apiBlog);

  data.author = data.author ? objectKeysToCamelCase(data.author) : undefined;

  if (data.author?.avatarUrl) {
    data.author.avatarUrl = data.author.avatarUrl
      .replace(".jpg", ".webp")
      .replace(".png", ".webp");
  }

  data.url =
    data.locale && data.locale !== "en"
      ? `/${data.locale}/${data.slug}`
      : `/${data.slug}`;

  data.heroImageUrl = data.heroImageUrl
    ?.replace(".jpg", ".webp")
    ?.replace(".png", ".webp");

  return removeEmpty(data);
};

export default transformBlogSummary;
