/* eslint-disable @typescript-eslint/no-unused-vars */
const removeEmpty = (obj: object): any => {
  return obj;
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce(
      (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }),
      {}
    );
};

export default removeEmpty;
