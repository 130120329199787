import type { CookieConsentConfig } from "vanilla-cookieconsent";
import enJson from "./en.json";
import esJson from "./es.json";

const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: "box",
      position: "left",
      equalWeightButtons: true,
      flipButtons: false
    }
  },

  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true // this category cannot be disabled
    },
    analytics: {},
    ads: {}
  },

  language: {
    default: "",
    translations: {
    }
}
};

export default pluginConfig;
