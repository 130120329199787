/* eslint-disable no-underscore-dangle */
import Config from "./config";

class API {
  constructor() {
    this.prefix = "/api";
    this.token = "";
  }

  groups(params = {}) {
    const url = this._query("/web/pages/groups", params, { limit: 10 });
    return this._fetch(url).then((response) => {
      if (!response.ok) return response;
      return response.json();
    });
  }

  group(id) {
    return this._fetch(`/web/pages/groups/${id}`).then((response) => {
      if (!response.ok) return response;
      return response.json();
    });
  }

  posts(query = {}) {
    const url = this._query("/web/posts", query, { limit: 32 });

    return this._fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (!data.posts) return [];
        return data.posts.map((p) =>
          Object.assign(p, { url: Config.paths.post(p.uid) })
        );
      });
  }

  post(id) {
    return this._fetch(`/web/posts/${id}`).then((response) => {
      if (!response.ok) return response;
      return response.json();
    });
  }

  router(slug, locale = "en") {
    return this._fetch(`/web/router?slug=${slug}&locale=${locale}`).then(
      (response) => {
        if (response.status >= 200 && response.status < 400)
          return response.json();
        if (response.status === 404) return { type: "404" };
        return { type: "error" };
      }
    );
  }

  seo_categories() {
    return this._fetch(`/web/seo/categories`).then((response) =>
      response.json()
    );
  }

  seo_pages(type, params = {}) {
    const url = this._query("/web/seo/pages", { type, ...params });
    return this._fetch(url).then((response) => {
      if (!response.ok) return response;
      return response.json();
    });
  }

  _fetch(url, params = {}) {
    const updatedParams = {
      method: "GET",
      mode: "cors",
      headers: this._headers(),
      ...params,
    };

    return fetch(`${this.prefix}${url}`, updatedParams);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _query(url, params) {
    const esc = encodeURIComponent;
    const queryString = Object.keys(params)
      .filter((k) => esc(params[k]).length > 0)
      .map((k) => `${esc(k)}=${esc(params[k])}`)
      .join("&");

    if (queryString.length === 0) return url;
    return `${url}?${queryString}`;
  }

  _headers() {
    const headers = new Headers();
    if (this.token) {
      headers.set("Authorization", `Basic ${this.token}`);
    }
    return headers;
  }
}

export default new API();
