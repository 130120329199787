import useSWR from 'swr';
import axios from 'helpers/axios';
import defaultSWROptions from 'helpers/defaultSWROptions';
import fetcher from './fetcher';

export interface ChatbotConfig {
    chatbotEnabled: boolean;
}

export interface UseChatbotConfigProps {
  userId: string;
}

const useChatbotConfig = ({ userId }: UseChatbotConfigProps) => {
  const { data, error, isLoading } = useSWR<ChatbotConfig>(
    {
      url:  userId ? `/api/web/${userId}/config` : null,
    },
    fetcher(axios),
    defaultSWROptions
  );
  
  return {
    config: data,
    error,
    loading: isLoading
  };
};

export default useChatbotConfig;
