/* eslint-disable import/no-cycle */
import Config from "./config";

export const EVENT_INSTALL_BANNER_CLICK = "Install Banner Click";
export const EVENT_SEND_LINK = "Send Link";

export const POST_INTERACTION_LIKE = "Post - Like";
export const POST_INTERACTION_COMMENT = "Post - Comment";
export const POST_INTERACTION_SHARE = "Post - Share Click";
export const SHARE_FACEBOOK = "Share - Facebook";
export const SHARE_TWITTER = "Share - Twitter";
export const SHARE_PINTEREST = "Share - Pinterest";
export const SHARE_WHATSAPP = "Share - Whatsapp";
export const SHARE_COPY_URL = "Share - Copy Url";

class Helpers {
  absoluteUrl(uri, path) {
    const { scheme } = Config.http;
    const { host } = uri;
    return `${scheme}://${host}${path}`;
  }

  gtm_custom_event(event) {
    window.dataLayer &&
      window.dataLayer.push({
        event: `custom.event.${event}`,
        "custom.gtm.originalEvent": event,
      });
  }
}

export default new Helpers();
