import { defineMessages } from "react-intl";

export const labels: {
  [key: string]: { id: string; description: string; defaultMessage: string };
} = defineMessages({
  about: {
    id: "generic.navigation.about",
    defaultMessage: "About",
    description: "Navigation: About"
  },
  aboutus: {
    id: "generic.navigation.aboutus",
    defaultMessage: "About us",
    description: "Navigation: About us"
  },
  blog: {
    id: "generic.navigation.blogindex",
    defaultMessage: "Blog",
    description: "Navigation: Blog"
  },
  careers: {
    id: "generic.navigation.careers",
    defaultMessage: "Careers",
    description: "Navigation: Careers"
  },
  community: {
    id: "generic.navigation.community",
    defaultMessage: "Community",
    description: "Navigation: Community"
  },
  download: {
    id: "generic.navigation.download",
    defaultMessage: "Download",
    description: "Navigation: Download"
  },
  experts: {
    id: "generic.navigation.experts",
    defaultMessage: "Experts",
    description: "Navigation: Experts"
  },
  guidelines: {
    id: "generic.navigation.guidelines",
    defaultMessage: "Guidelines",
    description: "Navigation: Guidelines"
  },
  home: {
    id: "generic.navigation.home",
    defaultMessage: "Home",
    description: "Navigation: Home"
  },
  medicalReview: {
    id: "generic.navigation.medicalreview",
    defaultMessage: "Medical Review",
    description: "Navigation: Medical review"
  },
  peanutAtWork: {
    id: "generic.navigation.peanutatwork",
    defaultMessage: "Peanut at Work",
    description: "Navigation: Peanut at Work"
  },
  privacy: {
    id: "generic.navigation.privacy",
    defaultMessage: "Privacy",
    description: "Navigation: Privacy"
  },
  offers: {
    id: "generic.navigation.offers",
    defaultMessage: "Offers",
    description: "Navigation: Offers"
  },
  shop: {
    id: "generic.navigation.shop",
    defaultMessage: "Shop",
    description: "Navigation: Shop"
  },
  sitemap: {
    id: "generic.navigation.sitemap",
    defaultMessage: "Sitemap",
    description: "Navigation: Sitemap"
  },
  starther: {
    id: "generic.navigation.starther",
    defaultMessage: "StartHer",
    description: "Navigation: StartHer"
  },
  terms: {
    id: "generic.navigation.terms",
    defaultMessage: "Terms",
    description: "Navigation: Terms"
  }
});

export const paths: {
  [key: string]: { id: string; description: string; defaultMessage: string };
} = defineMessages({
  about: {
    id: "generic.url.about",
    description:
      "Navigation Url: About. For example: https://www.peanut-app.io/about-us",
    defaultMessage: "about-us"
  },
  authorpage: {
    id: "generic.url.authorpage",
    description:
      "Navigation Url: Author. For example: https://www.peanut-app.io/author/<PAGE>",
    defaultMessage: "author"
  },
  blog: {
    id: "generic.url.blogindex",
    description:
      "Navigation Url: Blog. For example: https://www.peanut-app.io/blog",
    defaultMessage: "blog"
  },
  blogpage: {
    id: "generic.url.blogpage",
    description:
      "Navigation Url part: https://www.peanut-app.io/blog/<PAGE>--2 (page)",
    defaultMessage: "page"
  },
  blogtag: {
    id: "generic.url.blogtag",
    description:
      "Navigation Url part: https://www.peanut-app.io/blog/<TAG>/fertility-37  (tag or sectcion)",
    defaultMessage: "tag"
  },
  careers: {
    id: "generic.url.careers",
    description: "Navigation Url: Careers. USE DEFAULT VALUE",
    defaultMessage: "https://peanut.recruitee.com"
  },
  community: {
    id: "generic.url.community",
    description:
      "Navigation Url: Community. For example: https://www.peanut-app.io/community",
    defaultMessage: "posts"
  },

  communityindex: {
    id: "generic.url.communityindex",
    description:
      "Navigation Url: Community. For example: https://www.peanut-app.io/pregnancy/community",
    defaultMessage: "community"
  },

  duedatecalculator: {
    id: "generic.url.seocalculators.duedate",
    description:
      "Navigation Url: Due Date Calculator. For example: https://www.peanut-app.io/due-date-calculator",
    defaultMessage: "due-date-calculator"
  },

  page: {
    id: "generic.url.page",
    description:
      "Navigation Url: The current page number. For example: https://www.peanut-app.io/blog/<page>/1",
    defaultMessage: "page"
  },

  peanutAtWork: {
    id: "generic.url.peanutatwork",
    description:
      "Navigation Url: Peanut at Work. For example: https://www.peanut-app.io/peanut-at-work",
    defaultMessage: "peanut-at-work"
  },

  results: {
    id: "generic.url.partial.results",
    description:
      "Navigation Url: Results page for a quiz (Results page). For example: https://www.peanut-app.io/due-date-calculator/results",
    defaultMessage: "results"
  },

  download: {
    id: "generic.url.download",
    description:
      "Navigation Url: Download. For example: https://www.peanut-app.io/download",
    defaultMessage: "download"
  },
  experts: {
    id: "generic.url.experts",
    description:
      "Navigation Url: Experts. For example: https://www.peanut-app.io/experts",
    defaultMessage: "experts"
  },
  fertilityCommunity: {
    id: "generic.url.fertilitycommunity",
    defaultMessage: "fertility-community",
    description:
      "Navigation url: fertility-community (use - for spaces, no punctuation)"
  },

  genderpredictor: {
    id: "generic.url.seocalculators.genderpredictor",
    description:
      "Navigation Url: Due Date Calculator. For example: https://www.peanut-app.io/due-date-calculator",
    defaultMessage: "chinese-gender-predictor"
  },

  groups: {
    id: "generic.url.groups",
    description:
      "Navigation Url: Groups. For example: https://www.peanut-app.io/groups",
    defaultMessage: "groups"
  },
  guidelines: {
    id: "generic.url.guidelines",
    description:
      "Navigation Url: Guidelines. For example: https://www.peanut-app.io/community-guidelines",
    defaultMessage: "community-guidelines"
  },
  home: {
    id: "generic.url.home",
    description: "Navigation Url: Home. Now translation needed",
    defaultMessage: "/"
  },
  medicalReview: {
    id: "generic.url.medicalreview",
    defaultMessage: "medical-review",
    description:
      "Navigation Url: Medical Review. For example: https://www.peanut-app.io/medical-review"
  },
  motherhoodCommunity: {
    id: "generic.url.motherhoodcommunity",
    defaultMessage: "motherhood-community",
    description:
      "Navigation url: motherhood-community (use - for spaces, no punctuation)"
  },
  menopauseCommunity: {
    id: "generic.url.menopausecommunity",
    defaultMessage: "menopause-community",
    description:
      "Navigation url: menopause-community (use - for spaces, no punctuation)"
  },
  pod: {
    id: "generic.url.pod",
    defaultMessage: "pods",
    description: "Navigation url: pod (use - for spaces, no punctuation)"
  },
  pregnancyCommunity: {
    id: "generic.url.pregnancycommunity",
    defaultMessage: "pregnancy-community",
    description:
      "Navigation url: pregnancy-community (use - for spaces, no punctuation)"
  },
  pregnancyWeekByWeek: {
    id: "generic.url.pregnancyweekbyweek",
    defaultMessage: "pregnancy-week-by-week",
    description:
      "Navigation url: pregnancy-week-by-week (use - for spaces, no punctuation)"
  },
  privacy: {
    id: "generic.url.privacy",
    description:
      "Navigation Url: Privacy. For example: https://www.peanut-app.io/privacy",
    defaultMessage: "privacy"
  },
  offers: {
    id: "generic.url.offers",
    description:
      "Navigation Url: Offers. For example: https://www.peanut-app.io/offers",
    defaultMessage: "peanut-offers"
  },

  ovulation: {
    id: "generic.url.seocalculators.ovulation",
    description:
      "Navigation Url: Ovulation Calculator. For example: https://www.peanut-app.io/ovulation-calculator",
    defaultMessage: "ovulation-calculator"
  },

  shop: {
    id: "generic.url.shop",
    description:
      "Navigation Url: Shop. For example: https://www.peanut-app.io/shop",
    defaultMessage: "https://shop.peanut-app.io"
  },
  sitemap: {
    id: "generic.url.sitemap",
    description:
      "Navigation Url: Sitemap. For example: https://www.peanut-app.io/sitemap",
    defaultMessage: "sitemap"
  },
  starther: {
    id: "generic.url.starther",
    description:
      "Navigation Url: StartHer. Do not translate, please use English version",
    defaultMessage: "starther"
  },
  peanutNews: {
    id: "generic.url.peanutnews",
    description:
      "Navigation Url: PeanutNews. Do not translate, please use English version",
    defaultMessage: "from-peanut"
  },
  terms: {
    id: "generic.url.terms",
    description:
      "Navigation Url: Terms. For example: https://www.peanut-app.io/terms",
    defaultMessage: "terms"
  },
  ttc: {
    id: "generic.url.ttc",
    defaultMessage: "ttc",
    description: "Navigation url: /ttc (use - for spaces, no punctuation)"
  }
});

// Help (Editorial) Pages
export const helpPages = defineMessages({
  help: {
    id: "editorial.help.index.url",
    description:
      "Navigation Url: Help. For example: https://www.peanut-app.io/help (use - for spaces, no punctuation or none english characters)",
    defaultMessage: "help"
  },
  "help-account": {
    id: "editorial.help.account.url",
    description:
      "Navigation Url: Help. For example: https://www.peanut-app.io/help/help-feedback-and-support (use - for spaces, no punctuation or none english characters)",
    defaultMessage: "help-account"
  },
  "help-community-safety-and-wellbeing": {
    id: "editorial.help.safety.url",
    description:
      "Navigation Url: Help. For example: https://www.peanut-app.io/help/help-community-safety-and-wellbeing (use - for spaces, no punctuation or none english characters)",
    defaultMessage: "help-community-safety-and-wellbeing"
  },
  "help-connection": {
    id: "editorial.help.connection.url",
    description:
      "Navigation Url: Help. For example: https://www.peanut-app.io/help/help-connection (use - for spaces, no punctuation or none english characters)",
    defaultMessage: "help-connection"
  },
  "help-feedback-and-support": {
    id: "editorial.help.feedback.url",
    description:
      "Navigation Url: Help. For example: https://www.peanut-app.io/help/help-feedback-and-support (use - for spaces, no punctuation or none english characters)",
    defaultMessage: "help-feedback-and-support"
  },
  "help-pro": {
    id: "editorial.help.pro.url",
    description:
      "Navigation Url: Help. For example: https://www.peanut-app.io/help/help-feedback-and-support (use - for spaces, no punctuation or none english characters)",
    defaultMessage: "help-pro"
  },
  "help-technical": {
    id: "editorial.help.technical.url",
    description:
      "Navigation Url: Help. For example: https://www.peanut-app.io/help/help-feedback-and-support (use - for spaces, no punctuation or none english characters)",
    defaultMessage: "help-technical"
  }
});

export const sections = [
  // @ts-ignore
  ...new Set([...Object.keys(paths), ...Object.keys(labels)])
];

const sectionsMap = sections.reduce((acc, section) => {
  acc[section] = {
    label: labels[section],
    path: paths[section]
  };
  return acc;
}, {});

// e.g.
/* 
    {
        community: {
            label: "generic.navigation.community",
            path: "generic.url.community"
        }
    }
*/
export default sectionsMap;
