import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";
import { ApiSEOBlog } from "types/api/seo_blog";
import { SEOBlog } from "types/SEOBlog";
import { decode } from "html-entities";
import routeHelper from "i18n/routeHelper";
import transformTopicSummary from "../topicSummary";

import ImageGiveawayUK from "./assets/giveaway_uk.webp";
import ImageGiveawayUKx2 from "./assets/giveaway_uk@2x.webp";
import ImageGiveawayUKx3 from "./assets/giveaway_uk@3x.webp";

import ImageGiveawayLatam from "./assets/giveaway_latam.webp";
import ImageGiveawayLatamx2 from "./assets/giveaway_latam@2x.webp";
import ImageGiveawayLatamx3 from "./assets/giveaway_latam@3x.webp";

const transformBlogPost = (apiBlog: ApiSEOBlog): SEOBlog => {
  const data = objectKeysToCamelCase(apiBlog);

  data.references = apiBlog.references || [];

  data.url = routeHelper[data.locale].blogPost(data.slug);

  if (data.tag) {
    data.tag = objectKeysToCamelCase(data.tag);
  }

  if (data.author) {
    data.author = objectKeysToCamelCase(data.author);
  }

  // HACK - This is a temporary fix to reduce the compression on an image on a blog post.
  // TODO - This should be removed once the campaign is over - May 2023. Remove the better image from Google too.
  data.body = data.body?.replace(
    /<img.*?src="https:\/\/assets-production\.teampeanut\.com\/stored-image\/1200\/2a4em-77kk7g\.jpg\?ow=1200&amp;oh=675"[^>]+>/i,
    `<img src="${ImageGiveawayUK}?ow=1920&amp;oh=1080" srcset="${ImageGiveawayUK}?ow=1920&amp;oh=1080 1x, ${ImageGiveawayUKx2}?ow=1920&amp;oh=1080 2x, ${ImageGiveawayUKx3}?ow=1920&amp;oh=1080 3x">`
  );

  data.body = data.body?.replace(
    /<img.*?src="https:\/\/assets-production\.teampeanut\.com\/stored-image\/1200\/2abbk-79i99e\.jpg\?ow=1920&amp;oh=1080"[^>]+>/i,
    `<img src="${ImageGiveawayLatam}?ow=1920&amp;oh=1080" srcset="${ImageGiveawayLatam}?ow=1920&amp;oh=1080 1x, ${ImageGiveawayLatamx2}?ow=1920&amp;oh=1080 2x, ${ImageGiveawayLatamx3}?ow=1920&amp;oh=1080 3x">`
  );

  // Tempory hack to reduce image size:
  data.body = data.body?.replace("stored-image/1200/", "stored-image/750/");

  const primaryImageMatch = data.body?.match(/<img[^>]+src="([^">]+)"/i);

  if (primaryImageMatch) {
    const [, primaryImageUrl] = primaryImageMatch;

    if (primaryImageUrl) {
      data.primaryImageUrl = decode(
        primaryImageUrl.replace(".jpg", ".webp").replace(".png", ".webp")
      );
    }
  }
  data.flags = (apiBlog as any).flags || [];

  delete data.topic;
  delete data.subtopic;

  if (apiBlog.subtopic) {
    data.topic = transformTopicSummary(apiBlog.subtopic);
    data.topic.parent = transformTopicSummary(apiBlog.topic);
  } else if (apiBlog.topic) {
    data.topic = transformTopicSummary(apiBlog.topic);
  }

  return removeEmpty(data);
};

export default transformBlogPost;
