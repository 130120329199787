import authorSummary from "./authorSummary";
import blog from "./seo_blog";
import blogSummary from "./seo_blog_summary";
import cohortGroup from "./transformCohortGroup";
import communityPost from "./communityPost";
import geolocation from "./geolocation";
import landingPage from "./transformLandingPage";
import pagesGroup from "./pagesGroup";
import pod from "./pod";
import seoEditorial from "./seo_editorial";
import topic from "./topic";
import topicSummary from "./topicSummary";
import topicWithChildren from "./transformTopicWithChildren";

export const transformAuthorSummary = authorSummary;
export const transformBlog = blog;
export const transformBlogSummary = blogSummary;
export const transformCohortGroup = cohortGroup;
export const transformCommunityPost = communityPost;
export const transformEditorialPost = seoEditorial;
export const transformGeolocation = geolocation;
export const transformLandingPage = landingPage;
export const transformPagesGroup = pagesGroup;
export const transformPod = pod;
export const transformTopic = topic;
export const transformTopicSummary = topicSummary;
export const transformTopicWithChildren = topicWithChildren;
