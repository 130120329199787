/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/sort-comp */
/* eslint-disable react/static-property-placement */
import React from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";

export default class LazyLoad extends React.Component {
  static propTypes = {
    lazy: PropTypes.bool,
    onChange: PropTypes.func,
    offset: PropTypes.object,
  };

  render() {
    if (!this._shouldLazyLoad()) return this.props.children;

    const offset = this.props.offset || {};
    if (!offset.top) offset.top = -this._loadOffset();
    if (!offset.bottom) offset.bottom = -this._loadOffset();

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        onChange={this.props.onChange}
        offset={offset}
      >
        {this.props.children}
      </VisibilitySensor>
    );
  }

  componentDidMount() {
    if (!this._shouldLazyLoad() && this.props.onChange) {
      this.props.onChange(true);
    }
  }

  _shouldLazyLoad() {
    if (typeof navigator === "undefined") return true; // SSR
    if (navigator.userAgent.indexOf("Prerender") !== -1) return false;
    if (!this.props.lazy) return false;
    return true;
  }

  _loadOffset() {
    if (typeof window === "undefined") return 180; // SSR
    return Math.floor(window.innerHeight / 4);
  }
}
