/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/*
    Uses an image manifest to generate a webpack friendly picture element for
    An example manifest is in manifest.js
*/
/* eslint-disable array-callback-return */
import React from "react";
import PropTypes from "prop-types";

import {
  breakpointMobile,
  breakpointTablet,
  breakpointDesktop,
} from "../../constants";

const BREAKPOINTS = {
  desktop: breakpointDesktop,
  tabletLandscape: breakpointTablet,
  tablet: breakpointTablet,
  mobile: breakpointMobile,
  all: 0,
};

const FORMATS = ["webp", "jpg", "png", "svg"];

const ImageNextGen = ({
  alt,
  className,
  manifest,
  src,
  ...passthroughProps
}) => {
  const srcSet = (entries) => {
    const x = Object.entries(entries)
      .map(([resolution, image]) => {
        if (image === undefined) return;
        if (resolution === "1x") {
          return image;
        }
        return `${image} ${resolution}`;
      })
      .join(", ");

    return x;
  };

  const mediaQuery = (breakpoint) => {
    if (breakpoint === "mobile") {
      return `(max-width:${breakpointTablet}px)`;
    }
    if (breakpoint === "tabletLandscape") {
      return `(orientation: landscape) and (min-width: ${breakpointTablet}px)`;
    }
    return `(min-width:${BREAKPOINTS[breakpoint]}px)`;
  };

  return (
    <picture>
      {Object.keys(BREAKPOINTS).map((breakpointKey) => {
        const breakpoint = manifest[breakpointKey];
        if (!breakpoint) return;
        return FORMATS.map((formatKey) => {
          const format = breakpoint[formatKey];

          if (format === undefined) return;

          return (
            <source
              key={`${breakpointKey}-${formatKey}`}
              media={mediaQuery(breakpointKey)}
              srcSet={srcSet(format)}
            />
          );
        });
      })}

      <img src={src} alt={alt} className={className} {...passthroughProps} />
    </picture>
  );
};

ImageNextGen.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  manifest: PropTypes.any,
  passthroughProps: PropTypes.any,
  src: PropTypes.any,
};

export default ImageNextGen;
