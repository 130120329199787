/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/static-property-placement */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Page extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    plain: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      hidden: false,
    };
  }

  render() {
    return (
      <div
        className={classnames(
          "Page",
          this.props.plain && "Page--plain",
          this.props.className
        )}
      >
        {this.props.children}

      </div>
    );
  }
}
