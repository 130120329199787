import { AxiosInstance } from "axios";
import * as Sentry from "@sentry/browser";

const transformChatbotGetMessage = (data) => {
  return data;
};

const fetcher =
  (axios: AxiosInstance) =>
  ({ url, params }: { url: string; params?: any }) =>
    axios
      .get(url, {
        params,
      })
      .then((res) => {
        return transformChatbotGetMessage(res.data);
      })
      .catch((error) => {
        Sentry.captureException({
          source: "useChatbotGetMessage",
          error,
        });
        
        throw error;
      });

export default fetcher;
