/* eslint-disable no-param-reassign */
interface IObject {
  [key: string]: any;
}

export const toCamelCase = (e: string) =>
  e.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

const objectKeysToCamelCase = (obj: IObject): IObject => {
  if (obj == null || typeof obj !== "object") {
    return {};
  }

  const initial: IObject = {};

  return Object.keys(obj).reduce((result, key) => {
    let value = obj[key];
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value = value.map((v) => objectKeysToCamelCase(v));
      } else if (value) {
        value = objectKeysToCamelCase(value);
      }
    }

    result[toCamelCase(key)] = value;
    return result;
  }, initial);
};

export default objectKeysToCamelCase;
