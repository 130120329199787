/* eslint-disable react/prop-types */

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import Url from "url-parse";
import classnames from "classnames";

import LazyLoad from "./LazyLoad";

export default class ImageDiv extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    lazy: PropTypes.bool,
    className: PropTypes.string,
    alt: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { visible: !this.props.lazy, src: null };
  }

  render() {
    const { src, className, ...pass } = this.props;

    const ext = Url(src).pathname.replace(/.*\./, "");
    const webp = src?.replace(/\.(png|jpg)/, ".webp");

    return (
      <LazyLoad lazy={this.props.lazy} onChange={this._onVisibility.bind(this)}>
        <div
          {...pass}
          className={classnames("ImageDiv", className)}
          style={{
            backgroundImage:
              this.state.visible && this.state.src
                ? `url("${this.state.src}")`
                : null,
          }}
        >
          <picture className="ImageDiv-tester">
            <source srcSet={webp} type="image/webp" />
            <source srcSet={src} type={`image/${ext}`} />
            <img
              onLoad={this._onImageLoaded.bind(this)}
              src={src}
              alt={this.props.alt}
            />
          </picture>
          {this.props.children}
        </div>
      </LazyLoad>
    );
  }

  _onImageLoaded(el) {
    this.setState({ src: el.target.currentSrc || el.target.src });
  }

  _onVisibility(visible) {
    if (!visible) return;
    if (this.state.visible) return;
    this.setState({ visible: true });
  }
}
