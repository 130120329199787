import imageLogoMobile from "./assets/peanut-app-logo-mobile.svg";
import imageLogoDesktop from "./assets/peanut-app-logo.svg";

import imageMenuWebp from "./assets/menu.webp";
import imageMenu2xWebp from "./assets/menu@2x.webp";
import imageMenu3xWebp from "./assets/menu@3x.webp";

import imageMenuCloseWebp from "./assets/menu_close.webp";
import imageMenuClose2xWebp from "./assets/menu_close@2x.webp";
import imageMenuClose3xWebp from "./assets/menu_close@3x.webp";

const images = {
  imageMenu: {
    all: {
      webp: {
        "1x": imageMenuWebp,
        "2x": imageMenu2xWebp,
        "3x": imageMenu3xWebp,
      },
    },
  },
  imageMenuClose: {
    all: {
      webp: {
        "1x": imageMenuCloseWebp,
        "2x": imageMenuClose2xWebp,
        "3x": imageMenuClose3xWebp,
      },
    },
  },
  imageLogo: {
    mobile: {
      svg: {
        "1x": imageLogoMobile,
      },
    },
    desktop: {
      svg: {
        "1x": imageLogoDesktop,
      },
    },
  },
};

export default images;
