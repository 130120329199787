import removeEmpty from "helpers/removeEmpty";
import { ApiLandingPage } from "types/api/landing_page";

import { LandingPage } from "types/LandingPage";

const transformLandingPage = ({
  data: {
    body,
    heading,
    meta_title,
    meta_description,
    slug,
    summary,
    title,
    image_name,
    cta_button_url,
    cta_button_name
  }
}: ApiLandingPage): LandingPage => {
  const plaintextSummary = summary.replace(/<[^>]*>?/gm, "");

  console.log("cta_button_url", cta_button_url);
  console.log("cta_button_name", cta_button_name);
  console.log("image_name", image_name);
  return removeEmpty({
    body,
    heading,
    metaDescription: meta_description,
    metaTitle: meta_title,
    slug,
    summary: plaintextSummary,
    title,
    buttonText: cta_button_name,
    branchUrl: cta_button_url,
    imageName: image_name
  });
};
export default transformLandingPage;
