import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { goalShellyOpenClick, goalShellyLimitReached, goalShellyMinimize } from "helpers/goalTracking";
import useChatbotGetMessage from "hooks/useChatbotGetMessage";

interface Message {
  sender: string;
  body: string;
  isLoading?: boolean;
}

interface ChatData {
  message_limit: { left: number; total: number };
  messages: Message[];
  isOpen: boolean;
  isChatInitialized: boolean;
  showSurvey: boolean;
  surveyCompleted?: boolean;
}

interface ChatContextType {
  chatData: ChatData;
  userId: string;
  setChatData: React.Dispatch<React.SetStateAction<ChatData>>;
  toggleIsOpen: () => void;
  initializeChat: () => void;
  setShowSurvey: (show: boolean) => void;
  transformMessages: (messages: Message[]) => Message[];
  error: Error | null;
  setChatError: (newError: Error | null) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useChatContext = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};

const fakeIntroMessages = [
  { sender: "Shelly", body: "Hey! 😊 I'm Shelly, your AI-powered companion." },
  {
    sender: "Shelly",
    body: "What's on your mind today? Is there anything you're navigating right now that I can help with?",
  },
];

const ChatProvider: React.FC<{ children: ReactNode; userId: string }> = ({
  children,
  userId,
}) => {
  const { conversation, error: chatError, isLoading } = useChatbotGetMessage(userId);

  const [error, setError] = useState<Error | null>(null); 

  useEffect(() => {
    if (chatError) {
      setError(new Error("Error loading chat data."));
    } else {
      setError(null);
    }
  }, [chatError]);

  const setChatError = (newError: Error | null) => {
    setError(newError);
  };


  const [chatData, setChatData] = useState<ChatData>({
    message_limit: { left: 10, total: 10 },
    messages: [],
    isOpen: false,
    isChatInitialized: false,
    showSurvey: false,  

  });

  const transformMessages = (messages: Message[]) => {
    const updatedMessages: Message[] = [];
    messages.forEach((message) => {
      if (message.sender === 'Shelly') {
        const sentences = message?.body?.split(/(?<=[.!?])\s+/);
        let pair = '';
        sentences?.forEach((sentence, index) => {
          const trimmedSentence = sentence.trim();
          if (trimmedSentence) {
            pair += trimmedSentence;
            if ((index + 1) % 2 === 0 || index === sentences.length - 1) {
              updatedMessages.push({ ...message, body: pair });
              pair = '';
            } else {
              pair += '\n';
            }
          }
        });
      } else {
        updatedMessages.push(message);
      }
    });
    return updatedMessages;
  };
  

  useEffect(() => {
    if (conversation && !error && !isLoading) {
      const updatedMessages = transformMessages(conversation.messages);

      setChatData((currentData) => ({
        ...currentData,
        ...conversation,
        messages: [...fakeIntroMessages, ...updatedMessages],
        surveyCompleted: conversation.rating !== null,
        isChatInitialized: conversation.messages.length > 0,
      }));
    }
  }, [conversation, error, isLoading]);
  
  useEffect(() => {
    if (chatData.message_limit.left === 0) {
      goalShellyLimitReached();
    }
  }, [chatData.message_limit.left]);
  
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (chatData.message_limit.left === 0) {
        setChatData((currentData) => ({
          ...currentData,
          surveyCompleted: true,
        }));
      }
    }, 30000); // 30 seconds in milliseconds
  
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  
  }, [chatData.message_limit.left]);
  
  

  const toggleIsOpen = () => {
    setChatData((current) => {
      if (!current.isOpen) {
        goalShellyMinimize();
      } else {
        goalShellyOpenClick();
      }
      return {
        ...current,
        isOpen: !current.isOpen,
      };
    });
  };

  const setShowSurvey = (show: boolean) => {
    setChatData((current) => ({
      ...current,
      showSurvey: conversation?.message_limit.left === 0,
    }));
  };

  const initializeChat = () => {
    setChatData((current) => ({
      ...current,
      isChatInitialized: true,
    }));
  };

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error loading chat data.</div>;

  return (
    <ChatContext.Provider
      value={{
        chatData,
        userId,
        setChatData,
        toggleIsOpen,
        setShowSurvey,
        initializeChat,
        transformMessages,
        error,
        setChatError
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
