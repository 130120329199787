import React, { useCallback, useEffect } from "react";
import classnames from "classnames";
import useBranchLink from "hooks/useBranchLink";

export interface Props {
  children: React.ReactNode;
  className?: string;
  href: string;
  onClick?: (href: string) => void;
  openInNewTab?: boolean;
}

const DeepLink = ({
  children,
  className,
  href,
  onClick,
  openInNewTab = false,
}: Props) => {
  const { url, fetchUrl } = useBranchLink(href);

  useEffect(() => {
    if (!url) return;

    if (openInNewTab) {
      window.open(url, "_blank");
    } else {
      onClick && onClick(href);
      window.location.href = url;
    }
  }, [url, openInNewTab]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      if (!url) {
        fetchUrl();
      } else if (openInNewTab) {
        window.open(url, "_blank");
      }
    },
    [url, fetchUrl, openInNewTab]
  );

  return (
    /* eslint-disable-next-line react/jsx-no-target-blank */
    <a
      className={classnames("DeepLink", className)}
      href={url || href}
      onClick={handleClick}
      data-testid="deeplink"
      target={openInNewTab ? "_blank" : undefined}
      rel={openInNewTab ? "noopener noreferrer" : "noopener"}
    >
      {children}
    </a>
  );
};

export default DeepLink;
