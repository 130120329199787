import React, { createContext, useState } from "react";
import { IntlProvider as IntlProviderType } from "react-intl";

import i18nEn from "i18n/locales/en.json";
import i18nEs from "i18n/locales/es.json";

import "./blogCategories/definitions";
import "./routes/definitions";

export const I18nContext = createContext<{
  locale: string;
  setLocale: (locale: string) => void;
}>({ locale: "en", setLocale: () => {} });

interface IProps {
  children: React.ReactElement;
  locale: string;
}

const messages = {
  en: i18nEn,
  es: i18nEs,
} as { [key: string]: { [key: string]: string } };

const I18nProvider = ({ children, locale: passedLocale }: IProps) => {
  const [locale, setLocale] = useState(passedLocale);

  const contextValue = {
    locale,
    setLocale,
  };
  const IntlProvider: any = IntlProviderType;
  return (
    <I18nContext.Provider value={contextValue}>
      <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={messages[locale]}
      >
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
};

export default I18nProvider;
