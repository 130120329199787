/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-empty-function */
import { createContext } from "react";
import { GoogleAdPlacementPosition } from "types/GoogleAdPlacementPosition";

export interface GoogleAdManagerContextValue {
  addPlacement: (
    position: GoogleAdPlacementPosition,
    elementId: string
  ) => void;
  addPageTargeting: (key: string, value: string | string[]) => void;
}

///so this contex does nothing?
export default createContext<GoogleAdManagerContextValue>({
  addPlacement: (position: GoogleAdPlacementPosition, elementId: string) => {},
  addPageTargeting: (key: string, value: string | string[]) => {},
});
