import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";
import { ApiTopic } from "types/api/topic";
import { Topic } from "types/Topic";
import { TopicSummary } from "types/TopicSummary";

const transformTopic = (
  apiTopic: ApiTopic,
  parentTopic?: TopicSummary
): Topic => {
  const data = objectKeysToCamelCase(apiTopic);

  data.id = data.topicId;
  delete data.topicId;

  if (parentTopic) {
    data.parent = parentTopic;
  }

  data.hasChildren = apiTopic.subcategories?.length > 0;

  // TODO: Have these removed at the API level
  delete data.subcategories;
  delete data.status;
  delete data.slug;
  delete data.posts;

  return removeEmpty(data);
};

export default transformTopic;
