/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */

import useSessionStorage from "hooks/useSessionStorage";
import React, { createContext, useEffect, useState } from "react";

export interface ReferralData {
  branchMatchId?: string | null;
  branchReferrer?: string | null;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
}

export const initialValue: ReferralData = {};

export const ReferralDataContext = createContext<{
  data: ReferralData;
}>({ data: initialValue });

interface IProps {
  children: React.ReactNode;
  value?: ReferralData;
}

const ReferralDataProvider = ({ children, value }: IProps) => {
  const [data, setData] = useState<ReferralData>({});
  const isSSR = typeof window !== "object";

  const [branchMatchId, setBranchMatchId] = useSessionStorage(
    "_branch_match_id",
    value?.branchMatchId
  );

  const [branchReferrer, setBranchReferrer] = useSessionStorage(
    "_branch_referrer",
    value?.branchReferrer
  );

  const [utmSource, setUtmSource] = useSessionStorage(
    "utm_source",
    value?.utmSource
  );

  const [utmMedium, setUtmMedium] = useSessionStorage(
    "utm_medium",
    value?.utmMedium
  );

  const [utmCampaign, setUtmCampaign] = useSessionStorage(
    "utm_campaign",
    value?.utmCampaign
  );

  useEffect(() => {
    if (!isSSR && window.location) {
      const searchParams = new URLSearchParams(window.location.search);
      if (!branchMatchId) {
        setBranchMatchId(searchParams.get("_branch_match_id"));
      }

      if (!branchReferrer) {
        setBranchReferrer(searchParams.get("_branch_referrer"));
      }

      if (!utmSource) {
        setUtmSource(searchParams.get("utm_source"));
      }

      if (!utmMedium) {
        setUtmMedium(searchParams.get("utm_medium"));
      }

      if (!utmCampaign) {
        setUtmCampaign(searchParams.get("utm_campaign"));
      }
    }
  }, [isSSR]);

  return (
    <ReferralDataContext.Provider
      value={{
        data: {
          branchMatchId,
          branchReferrer,
          utmSource,
          utmMedium,
          utmCampaign
        }
      }}
    >
      {children}
    </ReferralDataContext.Provider>
  );
};

export default ReferralDataProvider;
