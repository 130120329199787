const COMMENT_READ_MORE_CLICK = "comment_read_more_branch_click";
const HEADER_IOS_CLICK = "header_ios_branch_click";
const HEADER_ANDROID_CLICK = "header_android_branch_click";
const HEADER_JOIN_APP_CLICK = "header_join_app_branch_click";
const LOCATION_WIDGET_IOS_CLICK = "location_widget_ios_branch_click";
const LOCATION_WIDGET_ANDROID_CLICK = "location_widget_android_branch_click";
const GROUP_CTA_CLICK = "group_cta_branch_click";
const GROUP_TITLE_CLICK = "group_title_branch_click";
const HOMEPAGE_DEFAULT_CLICK = "homepage_default_branch_click";
const DOWNLOADPAGE_DEFAULT_CLICK = "downloadpage_default_branch_click";
const DOWNLOAD_SEND_LINK = "download_send_link_success_branch_click";
const DOWNLOAD_IOS_CLICK = "download_ios_branch_click";
const DOWNLOAD_ANDROID_CLICK = "download_android_branch_click";
const MARKDOWN_CTA_CLICK = "markdown_cta_branch_click";

const POSTS_READ_MORE_CLICK = "posts_read_more_branch_click";
const SEND_LINK_SUCCESS = "send_link_success_branch_click";
const STORE_LINK_ANDROID_CLICK = "store_link_android_branch_click";
const STORE_LINK_IOS_CLICK = "store_link_ios_branch_click";
const MOBILE_BANNER_INSTALL_CLICK = "banner_download_click";
const SEO_CATEGORY_READ_MORE_CLICK = "seo_category_read_more_branch_click";
const SEO_SUBCATEGORY_READ_MORE_CLICK =
  "seo_subcategory_read_more_branch_click";
const EMAIL_CAPTURE_WITH_IMAGE_POPUP_VIEW =
  "email_capture_with_image_popup_view";
const EMAIL_CAPTURE_WITH_IMAGE_POPUP_EMAIL_SUBMIT =
  "email_capture_with_image_popup_email_submit";
const EMAIL_CAPTURE_WITH_IMAGE_FIELD_FOCUS =
  "email_capture_with_image_popup_field_focus";
const EMAIL_CAPTURE_WITH_IMAGE_LIFESTAGE_DATA_SUBMIT =
  "email_capture_with_image_popup_lifestage_data_submit";
const CALCULATOR_GENDER_PREDICTOR_CALCULATE_CLICK =
  "calculator_gender_predictor_calculate_button";
const CALCULATOR_GENDER_PREDICTOR_GROUP_CTA =
  "calculator_gender_predictor_group_cta";
const CALCULATOR_GENDER_PREDICTOR_START_OVER_CLICK =
  "calculator_gender_predictor_start_over";

const CALCULATOR_DUE_DATE_CALCULATE_CLICK =
  "calculator_due_date_calculate_button";
const CALCULATOR_DUE_DATE_GROUP_CTA = "calculator_due_date_group_cta";
const CALCULATOR_DUE_DATE_START_OVER_CLICK = "calculator_due_date_start_over";

const CALCULATOR_OVULATION_CALCULATE_CLICK =
  "calculator_ovulation_calculate_button";
const CALCULATOR_OVULATION_GROUP_CTA = "calculator_ovulation_group_cta";
const CALCULATOR_OVULATION_START_OVER_CLICK = "calculator_ovulation_start_over";

const TOPIC_PREGNANCY_COHORT_GROUP_CTA = "topic_pregnancy_cohort_group_cta";

const TRENDING_INSERT_BLOG_ASIDE_CTA = "trending_insert_blog_aside_cta";

const PAGES_GROUP_BREAKOUT_CTA = "ugc_joingroup_click";

const POPULAR_TOPIC_INSERT_CTA = "popular_topic_insert_cta";

const EXPERTS_JOIN_GROUP_CTA = "experts_join_group_cta";

const EXPERTS_DETAILS_GROUP_CTA = "experts_details_group_cta";

const COMMUNITY_POST_DOWNLOAD_APP_CTA = "community_post_download_app_cta";

const HOMEPAGE_ONBOARDING_CLICK = "homepage_onboarding_click";

const SHELLY_DISPLAY = "shelly_display";
const SHELLY_OPEN_CLICK = "shelly_open_click";
const SHELLY_STARTSESSION_CLICK = "shelly_startsession_click";
const SHELLY_INPUT_SELECT = "shelly_input_select";
const SHELLY_SUBMIT_QUESTION = "shelly_submit_question";
const SHELLY_LIMIT_REACHED = "shelly_limit_reached";
const SHELLY_MOBILE_DOWNLOAD_CTA = "shelly_mobile_download_cta";
const SHELLY_MINIMIZE = "shelly_minimize";

const LOGIN_CTA = "login_cta";
const JOIN_CTA = "join_cta";

const GLOBAL_POPULAR_READMORE_CLICK = "global_popular_readmore_click"
const GLOBAL_TRENDING_READMORE_CLICK = "global_trending_readmore_click"

const ARTICLE_ARTICLELINK_CLICK = "article_articlelink_click";
const ARTICLE_POPULAR_CLICK = "article_popular_click";
const ARTICLE_COMMUNITYTRENDING_CLICK = "article_communitytrending_click";
const ARTICLE_CONTENTS_CLICK = "article_contents_click";
const ARTICLE_GROUPREC_CLICK = "article_grouprec_click";
const ARTICLE_GROUPREC_DISPLAY = "article_grouprec_display";

const ARTICLE_COMMUNITYPROMO_CLICK = "article_communitypromo_click";
const ARTICLE_COMMUNITYPROMO_DISPLAY = "article_communitypromo_display";

const ARTICLE_COMMUNITYPOSTS_CLICK = "article_communityposts_click";

const FOOTER_DOWNLOAD_CLICK = "footer_downloadpage_click";
const FOOTER_IOS_CLICK = "footer_ios_click";
const FOOTER_ANDROID_CLICK = "footer_android_click";

const NAV_ITEM_CLICK = "nav_item_click";

const HUB_COMMUNITYPOST_CLICK = "hub_communitypost_click";
const HUB_ARTICLE_CLICK = "hub_article_click";
const HUB_MORE_BUTTON_CLICK = "hub_morebutton_click";

const HUB_FACTCHECK_CLICK = "hub_factcheck_click";

const UGC_LIKESHARELOVE_CLICK = "ugc_likesharelove_click";
const UGC_GROUP_CLICK = "ugc_group_click";
const UGC_GUIDELINES_CLICK = "ugc_guidelines_click";
const UGC_ADDCOMMENT_CLICK = "ugc_addcomment_click";
const UGC_READMORE_CLICK = "ugc_readmore_click";
const UGC_TRENDINGCOMMUNITY_CLICK = "ugc_trendingcommunity_click";

const UGC_GROUPPOSTREC_CLICK = "ugc_grouppostrec_click";

const getTypeFromLocation = () => {
  const location = window.location.pathname;
  if (location?.startsWith("/blog")) {
    return 'blog';
  } else if (location?.startsWith("/posts")) {
    return 'post';
  } else {
    return 'unknown';
  }
};

export const goalTracking = (event, value = undefined) => {
  // Prepare the current URL to be included with every event
  const currentPageUrl = typeof window !== "undefined" ? window.location.href : "";

  if (!["test", "production"].includes(process.env.NODE_ENV)) {
    // eslint-disable-next-line no-console
    console.log(`goalTracking:`, event, value, currentPageUrl);
  }

  // GA4 Events with URL
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", event, {
      // If you're using GA4, you might want to adjust the parameter name and how it's included
      page_location: currentPageUrl, // This line adds the URL to GA4 events
      ...value // This allows for the inclusion of any additional data passed in `value`
    });
  }

  // Enhanced dataLayer push with URL for GTM
  window.dataLayer &&
    window.dataLayer.push({
      event: `custom.event.${event}`,
      "custom.gtm.originalEvent": event,
      "page_location": currentPageUrl, // This line adds the URL for GTM tracking
    });
};

export const goalEmailCaptureWithImagePopupView = () => {
  goalTracking(EMAIL_CAPTURE_WITH_IMAGE_POPUP_VIEW);
};
export const goalEmailCaptureWithImagePopupEmailSubmit = () => {
  goalTracking(EMAIL_CAPTURE_WITH_IMAGE_POPUP_EMAIL_SUBMIT);
};
export const goalEmailCaptureWithImagePopupLifestageDataSubmit = (payload) => {
  goalTracking(EMAIL_CAPTURE_WITH_IMAGE_LIFESTAGE_DATA_SUBMIT, payload);
};
export const goalEmailCaptureFieldFocus = (field) => {
  goalTracking(EMAIL_CAPTURE_WITH_IMAGE_FIELD_FOCUS, field);
};
export const goalCommentReadMoreClick = () => {
  goalTracking(COMMENT_READ_MORE_CLICK);
};
export const goalDownloadAndroidClick = () => {
  goalTracking(DOWNLOAD_ANDROID_CLICK);
};
export const goalDownloadSendLinkClick = () => {
  goalTracking(DOWNLOAD_SEND_LINK);
};
export const goalDownloadIosClick = () => {
  goalTracking(DOWNLOAD_IOS_CLICK);
};
export const goalFooterIosClick = () => {
  goalTracking(FOOTER_IOS_CLICK);
};
export const goalFooterAndroidClick = () => {
  goalTracking(FOOTER_ANDROID_CLICK);
};

export const goalLocationWidgetIosClick = () => {
  goalTracking(LOCATION_WIDGET_IOS_CLICK);
};
export const goalLocationWidgetAndroidClick = () => {
  goalTracking(LOCATION_WIDGET_ANDROID_CLICK);
};
export const goalGroupTitleClick = () => {
  goalTracking(GROUP_TITLE_CLICK);
};
export const goalGroupCTAClick = () => {
  goalTracking(GROUP_CTA_CLICK);
};
export const goalHeaderAndroidClick = () => {
  goalTracking(HEADER_ANDROID_CLICK);
};
export const goalHeaderIosClick = () => {
  goalTracking(HEADER_IOS_CLICK);
};
export const goalHeaderJoinAppClick = () => {
  goalTracking(HEADER_JOIN_APP_CLICK);
};
export const goalHomeDefaultClick = () => {
  goalTracking(HOMEPAGE_DEFAULT_CLICK);
};
export const goalDownloadDefaultClick = () => {
  goalTracking(DOWNLOADPAGE_DEFAULT_CLICK);
};
export const goalMarkdownCTAClick = () => {
  goalTracking(MARKDOWN_CTA_CLICK);
};
export const goalMobileBannerInstallClick = () => {
  goalTracking(MOBILE_BANNER_INSTALL_CLICK);
};
export const goalPostsReadMore = () => {
  goalTracking(POSTS_READ_MORE_CLICK);
};
export const goalSendLinkSuccess = () => {
  goalTracking(SEND_LINK_SUCCESS);
};
export const goalSEOCategoryReadMore = () => {
  goalTracking(SEO_CATEGORY_READ_MORE_CLICK);
};
export const goalSEOSubcategoryReadMore = () => {
  goalTracking(SEO_SUBCATEGORY_READ_MORE_CLICK);
};

export const goalTrendingInsertBlogAsideCTA = () => {
  goalTracking(TRENDING_INSERT_BLOG_ASIDE_CTA);
};

export const goalPagesGroupBreakoutCTA = () => {
  goalTracking(PAGES_GROUP_BREAKOUT_CTA);
};

export const goalPopularTopicInsertCTA = () => {
  goalTracking(POPULAR_TOPIC_INSERT_CTA);
};

export const goalExpertsJoinGroupCTA = (expertId) => {
  goalTracking(EXPERTS_JOIN_GROUP_CTA, expertId);
};

export const goalExpertsDetailsGroupCTA = (expertId) => {
  goalTracking(EXPERTS_DETAILS_GROUP_CTA, expertId);
};

export const goalCalculatorGenderPredictorGroupCTA = () => {
  goalTracking(CALCULATOR_GENDER_PREDICTOR_GROUP_CTA);
};
export const goalCalculatorGenderPredictorCalculateClick = () => {
  goalTracking(CALCULATOR_GENDER_PREDICTOR_CALCULATE_CLICK);
};
export const goalCalculatorGenderPredictorStartOverClick = () => {
  goalTracking(CALCULATOR_GENDER_PREDICTOR_START_OVER_CLICK);
};
export const goalCalculatorDueDateGroupCTA = () => {
  goalTracking(CALCULATOR_DUE_DATE_GROUP_CTA);
};
export const goalCalculatorDueDateCalculateClick = () => {
  goalTracking(CALCULATOR_DUE_DATE_CALCULATE_CLICK);
};
export const goalCalculatorDueDateStartOverClick = () => {
  goalTracking(CALCULATOR_DUE_DATE_START_OVER_CLICK);
};
export const goalCalculatorOvulateGroupCTA = () => {
  goalTracking(CALCULATOR_OVULATION_GROUP_CTA);
};
export const goalCalculatorOvulateCalculateClick = () => {
  goalTracking(CALCULATOR_OVULATION_CALCULATE_CLICK);
};
export const goalCalculatorOvulateStartOverClick = () => {
  goalTracking(CALCULATOR_OVULATION_START_OVER_CLICK);
};
export const goalCommunityPostDownloadAppCTA = (buttonIndex) => {
  goalTracking(COMMUNITY_POST_DOWNLOAD_APP_CTA, buttonIndex);
};
export const goalTopicPregnancyCohortGroupCTA = (groupUid) => {
  goalTracking(TOPIC_PREGNANCY_COHORT_GROUP_CTA, groupUid);
};

export const goalShellyDisplay = () => {
  goalTracking(SHELLY_DISPLAY);
};

export const goalShellyOpenClick = () => {
  goalTracking(SHELLY_OPEN_CLICK);
};

export const goalShellyStartSessionClick = () => {
  goalTracking(SHELLY_STARTSESSION_CLICK);
};

export const goalShellyInputSelect = () => {
  goalTracking(SHELLY_INPUT_SELECT);
};

export const goalShellySubmitQuestion = (questionNumber) => {
  goalTracking(SHELLY_SUBMIT_QUESTION, questionNumber);
};

export const goalShellyLimitReached = () => {
  goalTracking(SHELLY_LIMIT_REACHED);
};

export const goalShellyMobileDownloadCTA = () => {
  goalTracking(SHELLY_MOBILE_DOWNLOAD_CTA);
};

export const goalShellyMinimize = () => {
  goalTracking(SHELLY_MINIMIZE);
};

export const goalLoginCTA = () => {
  goalTracking(LOGIN_CTA);
};
export const goalJoinCTA = () => {
  goalTracking(JOIN_CTA);
};
export const goalHomepageOnboardingClick = () => {
  goalTracking(HOMEPAGE_ONBOARDING_CLICK);
}

export const goalGlobalPopularReadMoreClick = async () => {
  const location = await getTypeFromLocation();


  goalTracking(GLOBAL_POPULAR_READMORE_CLICK, location);
};

export const goalGlobalTrendingReadMoreClick = async () => {
  const location = await getTypeFromLocation();

  goalTracking(GLOBAL_TRENDING_READMORE_CLICK, location);
};

export const goalArticleArticleLinkClick = (href) => {
  goalTracking(ARTICLE_ARTICLELINK_CLICK, href);
};
export const goalArticlePopularClick = () => {
  goalTracking(ARTICLE_POPULAR_CLICK);
};
export const goalArticleCommunityTrendingClick = () => {
  goalTracking(ARTICLE_COMMUNITYTRENDING_CLICK);
};
export const goalArticleContentsClick = (link) => {
  goalTracking(ARTICLE_CONTENTS_CLICK, link);
};
export const goalArticleGroupRecClick = (groupUid) => {
  goalTracking(ARTICLE_GROUPREC_CLICK, groupUid);
};
export const goalArticleGroupRecDisplay = (groupUid) => {
  goalTracking(ARTICLE_GROUPREC_DISPLAY, groupUid);
};
export const goalArticleCommunityPromoClick = () => {
  goalTracking(ARTICLE_COMMUNITYPROMO_CLICK);
};
export const goalArticleCommunityPromoDisplay = () => {
  goalTracking(ARTICLE_COMMUNITYPROMO_DISPLAY);
};
export const goalArticleCommunityPostsClick = () => {
  goalTracking(ARTICLE_COMMUNITYPOSTS_CLICK);
};

export const goalFooterDownloadClick = () => {
  goalTracking(FOOTER_DOWNLOAD_CLICK);
};

export const goalNavItemClick = (navItem) => {
  goalTracking(NAV_ITEM_CLICK, navItem);
};

export const goalHubCommunityPostClick = () => {
  goalTracking(HUB_COMMUNITYPOST_CLICK);
};
export const goalHubArticleClick = () => {
  goalTracking(HUB_ARTICLE_CLICK);
};
export const goalHubMoreButtonClick = (destinationUrl) => {
  goalTracking(HUB_MORE_BUTTON_CLICK, destinationUrl);
}
export const goalHubFactCheckClick = () => {
  goalTracking(HUB_FACTCHECK_CLICK);
};

export const goalUgcLikeShareLoveClick = () => {
  goalTracking(UGC_LIKESHARELOVE_CLICK);
};
export const goalUgcGroupClick = async () => {
  await goalTracking(UGC_GROUP_CLICK); // Assuming goalTracking could be async
};
export const goalUgcGuidelinesClick = () => {
  goalTracking(UGC_GUIDELINES_CLICK);
};
export const goalUgcAddCommentClick = () => {
  goalTracking(UGC_ADDCOMMENT_CLICK);
};
export const goalUgcReadMoreClick = () => {
  goalTracking(UGC_READMORE_CLICK);
};
export const goalUgcTrendingCommunityClick = () => {
  goalTracking(UGC_TRENDINGCOMMUNITY_CLICK);
};

export const goalUgcGroupPostRecClick = () => {
  goalTracking(UGC_GROUPPOSTREC_CLICK);
};

export const goalStoreLinkClick = (platform) => {
  let goal;
  if (platform === "ios") {
    goal = STORE_LINK_IOS_CLICK;
  } else if (platform === "android") {
    goal = STORE_LINK_ANDROID_CLICK;
  } else {
    return;
  }
  goalTracking(goal);
};
export default { goalTracking };
