import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "helpers/axios";

const ResponseInterceptor = () => {
  const navigate = useNavigate();
  const interceptorId = useRef<number | null>(null);

  useEffect(() => {
    interceptorId.current = axiosInstance.interceptors.response.use(
      undefined,
      (axiosError) => {
        switch (axiosError.response.status) {
          case 503:
            navigate("/503");
            break;
          default:
            break;
        }
        return Promise.reject(axiosError); // Reject the Promise to propagate the error further if needed.
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(
        interceptorId.current as number
      );
    };
  }, [navigate]);

  return null;
};

export default ResponseInterceptor;
