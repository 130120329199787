import React, { useMemo, ElementType, useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// This needs to be .js for now - See https://github.com/remix-run/react-router/issues/8353
// eslint-disable-next-line import/extensions
import { StaticRouter } from "react-router-dom/server.js";

import WindowLocationProvider from "hooks/useWindowLocation/WindowLocationProvider";
import PartyTownContainer from "components/PartyTownContainer";

import ReferralDataProvider from "hooks/useReferralData/ReferralDataProvider";

import ChrissyPost from "pages/ChrissyPost";
import routes from "i18n/routes";

import { v4 as uuidv4 } from 'uuid';
import useLocalStorage from "hooks/useLocalStorage";
import { SWRConfig } from "swr";

import GoogleAdManagerProvider from "providers/GoogleAdManagerProvider";
import ResponseInterceptor from "providers/ResponseInterceptorProvider/ResponseInterceptorProvider";
import NotFound from "pages/NotFound";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { CookieConsentContextProvider } from "components/CookieConsent/CookieConsentContext";
import CookieConsentComponent from "components/CookieConsent/CookieConsentComponent";
import ChatbotProvider from "providers/ChatbotProvider";
import loadable from "@loadable/component";
import useChatbotConfig from "hooks/useChatbotConfig";
interface IProps {
  location: URL | undefined;
  store: any;
  cache?: { [key: string]: any };
}

const Chatbot: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-Chatbot" */
      "./components/ChatBot"
    )
);


function App({ store, location: passedLocation, cache }: IProps) {
  const [distinctId, setDistinctId] = useLocalStorage<string>(
    "distinctId",
    ""
  );

  useEffect(() => {
    if (!distinctId) {
      const newId = uuidv4();
      setDistinctId(newId);
    }
  }, [distinctId, setDistinctId]);


  const { config } = useChatbotConfig({ userId: distinctId });

  const RouterComponent: ElementType = useMemo(
    () => (passedLocation ? StaticRouter : BrowserRouter),
    [passedLocation]
  );

  const location = useMemo(() => passedLocation || window.location, []);

  const isSSR = typeof window !== "object";

  const createProvider = () => {
    const providerCache = new Map();
    const dataSource: { [key: string]: any } = isSSR
      ? cache
      : // eslint-disable-next-line no-underscore-dangle
      (window as any).__CACHE;

    dataSource &&
      Object.entries(dataSource).forEach(([key, data]) => {
        providerCache.set(key, { data, isLoading: false, error: null });
      });

    return providerCache;
  };

  return (
    <>
      <SWRConfig value={{ provider: createProvider }}>
        <CookieConsentContextProvider>
          {/* <PartyTownContainer /> */}
          <GoogleAdManagerProvider>
            <WindowLocationProvider value={location}>
              <ReferralDataProvider>
                <div className="App">
                  <RouterComponent
                    location={passedLocation?.pathname}
                    context={store}
                  >
                    <Routes>
                      {Object.values(routes).map((route) => route())}
                      <Route
                        path="/motherhood/feeling-shitty--p24l-knr"
                        element={<ChrissyPost />}
                      />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                    <ResponseInterceptor />
                  </RouterComponent>
                </div>
                <CookieConsentComponent />
              </ReferralDataProvider>
            </WindowLocationProvider>
          </GoogleAdManagerProvider>
        </CookieConsentContextProvider>
        {config?.chatbotEnabled && (
          <ChatbotProvider userId={distinctId}>
            <Chatbot />
          </ChatbotProvider>
        )}
      </SWRConfig>
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `window.__CACHE=${JSON.stringify(cache)}`,
        }}
      />
    </>
  );
}

export default App;
