import React, { useCallback, useMemo, MouseEvent } from "react";
import classNames from "classnames";

import styles from "./Button.module.scss";

export type Props = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  hollow?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
};

function Button({
  children,
  className,
  disabled,
  hollow,
  onClick,
  testId = "button",
}: Props) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onClick && onClick(event);
    },
    [onClick]
  );

  const processedClassNames = useMemo(() => {
    const classes = {
      [styles.button]: true,
      [styles.hollow]: hollow,
    };
    if (className) {
      classes[className] = true;
    }
    return classes;
  }, [hollow, className]);

  return (
    <button
      className={classNames(processedClassNames)}
      disabled={disabled}
      onClick={handleClick}
      data-testid={testId}
      type="button"
    >
      {children}
    </button>
  );
}

export default Button;
