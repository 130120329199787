/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Markdown from "./Markdown";

export default class PageSection extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    htmlContent: PropTypes.string,
  };

  render() {
    if (this.props.htmlContent) {
      return (
        <Markdown
          className={classnames("PageSection", this.props.className)}
          htmlContent={this.props.htmlContent}
        >
          {this.props.children}
        </Markdown>
      );
    }
    return (
      <div className={classnames("PageSection", this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}
