import React, { useEffect } from "react";
import { GoogleAdPlacementPosition } from "types/GoogleAdPlacementPosition";

import GoogleAdManagerContext, {
  GoogleAdManagerContextValue,
} from "./GoogleAdManagerContext";

declare global {
  interface Window {
    googletag: any;
  }
}
interface IProps {
  children: React.ReactNode;
  isEnabled?: boolean;
}

const RESPONSIVE_AD_MAP = {
  header: [
    [
      [768, 400],
      [
        [728, 90],
        [750, 100],
        [750, 200],
        [750, 300],
      ],
    ],
    [
      [300, 400],
      [
        [336, 280],
        [300, 250],
        [300, 50],
      ],
    ],
    [[0, 0], []],
  ],
  content: [
    [
      [768, 200],
      [
        [728, 90],
        [750, 100],
        [750, 200],
        [750, 300],
      ],
    ],
    [
      [300, 200],
      [
        [336, 280],
        [300, 250],
        [300, 100],
        [300, 75],
        [300, 50],
      ],
    ],
    [[0, 0], []],
  ],
  skyscraper: [
    [
      [1200, 200],
      [
        [160, 600],
        [240, 400],
      ],
    ],
    [[0, 0], []],
  ],
  stickyTop: [
    [[0, 0], [[300, 50]]],
    [[0, 0], []],
  ],
};

const UNIT_ID_MAP: { [key: string]: string } = {
  header: "/22824695316/Web/web-blog-header",
  content: "/22824695316/Web/web-blog-content",
  skyscraper: "/22824695316/Web/web-sticky-sides",
  stickyTop: "/22824695316/Web/web-sticky-top",
};

const writeScriptToDom = (script: string) => {
  if (typeof window.googletag === "undefined") return;
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.innerHTML = `
    ${script}
  `;
  document.body.appendChild(s);
};

const writeGoogleCmdToDom = (cmd: string) => {
  if (typeof window.googletag === "undefined") return;
  writeScriptToDom(`
    googletag.cmd.push(function() {
      ${cmd}
    });
  `);
};

const GoogleAdManagerProvider = ({ children }: IProps) => {
  const [slots, setSlots] = React.useState<string[]>([]);

  useEffect(() => {
    const initializeGPT = () => {
      const s = document.createElement("script");
      s.async = true;
      s.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      s.onload = () => {
        // Initialize googletag once the script is loaded
        window.googletag = window.googletag || { cmd: [] };
        writeScriptToDom(`window.googletag = window.googletag || {cmd: []};`);
      };
      document.body.appendChild(s);
    };

    initializeGPT();
  }, []);

  const addPlacement = (
    position: GoogleAdPlacementPosition,
    elementId: string
  ) => {
    if (slots.includes(elementId)) return;

    setSlots((previousSlots: string[]) => [...previousSlots, elementId]);

    const unitCode = UNIT_ID_MAP[position];
    const responsiveMapping = RESPONSIVE_AD_MAP[position];
    const combinedSizes = responsiveMapping
      .filter(([, sizes]) => sizes.length > 0)
      .map(([, sizes]) => (sizes as number[][]).map(([w, h]) => `[${w}, ${h}]`))
      .join(",");

    const responsiveMappingString = `[${responsiveMapping
      .map(
        ([query, sizes]) =>
          `[[${query[0]},${query[1]}], [${(sizes as number[][]).map(
            ([w, h]) => `[${w}, ${h}]`
          )}]]`
      )
      .join(",")}]`;

    writeGoogleCmdToDom(`
      const responsiveAdSlot = googletag.defineSlot('${unitCode}', [${combinedSizes}], '${elementId}')
                                .addService(googletag.pubads());
      responsiveAdSlot.defineSizeMapping(${responsiveMappingString});


      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
      googletag.display('${elementId}');

    `);
  };

  const addPageTargeting = (key: string, value: string | string[]) => {
    const writtenValue = Array.isArray(value)
      ? `[${value.map((v) => `"${v}"`).join(",")}]`
      : `"${value}"`;
    writeGoogleCmdToDom(`
        googletag.pubads().setTargeting('${key}', ${writtenValue});
    `);
  };

  const contextValue: GoogleAdManagerContextValue = {
    addPlacement,
    addPageTargeting,
  };

  return (
    <GoogleAdManagerContext.Provider value={contextValue}>
      {children}
    </GoogleAdManagerContext.Provider>
  );
};

export default GoogleAdManagerProvider;
