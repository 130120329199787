import { ApiGeolocation } from "types/api/geolocation";
import { Geolocation } from "types/Geolocation";
import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";

const transformGeolocation = (apiGeolocation: ApiGeolocation): Geolocation => {
  const data = objectKeysToCamelCase(apiGeolocation);
  return removeEmpty(data);
};

export default transformGeolocation;
