/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class EmbedYoutube extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  render() {
    const id = this.props.url
      .replace(/https:\/\/www\.youtube\.com\/watch\?v=([A-Za-z0-9_]+)/, "$1")
      .replace(/https:\/\/youtu\.be\/([A-Za-z0-9_]+)/, "$1");
    return (
      <div className={classnames("EmbedYoutube", this.props.className)}>
        <iframe
          title={this.props.title}
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  }
}
