import { ApiUserSummary } from "types/api/user_summary";
import IUserSummary from "types/userSummary";
import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";

const transformUserSummary = (apiPagesGroup: ApiUserSummary): IUserSummary => {
  const data = objectKeysToCamelCase(apiPagesGroup);

  data.displayName = [data.firstName, data.lastName].join(" ");
  if (data.location === null) {
    data.location = undefined;
  }
  return removeEmpty(data);
};

export default transformUserSummary;
