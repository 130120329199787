/* eslint-disable import/no-named-as-default */
import { ApiPagesGroup } from "types/api/pages_group";
import { PagesGroup } from "types/pagesGroup";
import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";

import transformUserSummary from "./userSummary";

const transformPagesGroup = (apiPagesGroup: ApiPagesGroup): PagesGroup => {
  const data = objectKeysToCamelCase(apiPagesGroup);

  data.deepLink = data.deeplink;
  delete data.deeplink;
  data.author = transformUserSummary(apiPagesGroup.author);
  return removeEmpty(data);
};

export default transformPagesGroup;
