import { ApiTopicSummary } from "types/api/topic_summary";
import { TopicSummary } from "types/TopicSummary";

const transformTopicSummary = (apiTopic: ApiTopicSummary): TopicSummary => {
  let data: TopicSummary;

  // TODO: The API gives inconsistence results.
  //       Remove this when it gives a good return value.
  if ((apiTopic as any)?.id) {
    data = {
      id: (apiTopic as any).id,
      title: (apiTopic as any).name,
      path: `/${(apiTopic as any).slug}`,
      summary: (apiTopic as any).summary,
    };
  } else {
    data = {
      id: apiTopic.topic_id,
      title: apiTopic.title,
      path: apiTopic.path,
      summary: apiTopic.summary
    };
  }

  if (apiTopic.subcategories) {
    data.children = apiTopic.subcategories.map(transformTopicSummary);
  }

  return data;
};

export default transformTopicSummary;
