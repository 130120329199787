import { ApiCommunityPost } from "types/api/post";
import { CommunityPost } from "types/CommunityPost";
import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";

import ellipsize from "ellipsize";

import routeHelper from "i18n/routeHelper";
import transformCommunityPostComment from "./communityPostComment";
import Config from "../../config";
import transformTopicSummary from "./topicSummary";

const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

const transformCommunityPost = (apiPost: ApiCommunityPost): CommunityPost => {
  const data = removeEmpty(objectKeysToCamelCase(apiPost));

  data.commentCount = apiPost.n_comments;
  data.impressionCount = apiPost.n_impressions;
  data.likeCount = apiPost.n_likes;
  data.viewCount = apiPost.n_views;

  data.author.displayName = data.author.displayName || data.author.firstName;

  delete data.nImpressions;
  delete data.nComments;
  delete data.nLikes;
  delete data.nViews;

  data.comments = apiPost.comments?.map(transformCommunityPostComment);
  // Ensure there is a meta title
  data.metaTitle = data.metaTitle || data.title;

  // Ensure there is an excerpt
  data.excerpt =
    data.metaDescription || data.body
      ? ellipsize(data.metaDescription || data.body, 320)
      : Config.placeholders.communityPostDescription;

  data.imageUrl =
    data.imageUrl === null
      ? undefined
      : data.imageUrl.replace(".jpg", ".webp").replace(".png", ".webp");

  data.categoryCode =
    data.categoryCode === null ? undefined : data.categoryCode;

  delete data.topic;
  delete data.subtopic;

  data.slug = slugify(data.title);

  //data path should be data.path concatenated with data.slug
  data.path = data.path + "/" + data.slug;

  let locale:string;
  if (data.locale !== "en" || data.locale !== "es") {
    locale = "en";
  } else {
    locale = data.locale;
  }

  // Set the posts external url
  data.url = routeHelper[locale].communityPost(data.uid, data.slug);

  if (apiPost.subtopic) {
    data.topic = transformTopicSummary(apiPost.subtopic);
    data.topic.parent = apiPost.topic
      ? transformTopicSummary(apiPost.topic)
      : undefined;
  } else if (apiPost.topic) {
    data.topic = transformTopicSummary(apiPost.topic);
  }

  return removeEmpty(data);
};

export default transformCommunityPost;
