import React from "react";
import { useIntl, defineMessages } from "react-intl";
import PageFooter from "components/PageFooter";
import PageSection from "components/PageSection";
import Page from "components/Page";
import PageHeaderBar from "components/PageHeaderBar";
import Helmet from "components/Helmet";
import BubbleLink from "components/BubbleLink";
import localeRouteHelper from "i18n/routeHelper";
import image from "./assets/womengroup.webp";
import styles from "./NotFound.module.scss";

interface NotFoundProps {
  status?: number;
}

const titles: {
  [key: number]: { id: string; defaultMessage: string; description: string };
} = defineMessages({
  404: {
    id: "pages.notfound.hero.title",
    defaultMessage: "Oops! That page {br} doesn’t exist.",
    description: "Not Found page: Hero Title",
  },
  503: {
    id: "pages.notfound.hero.title.serviceUnavailable",
    defaultMessage: "We're having a glow up.",
    description: "Not Found page: Hero Title for 503 status",
  },
});

const subtitles: {
  [key: number]: { id: string; defaultMessage: string; description: string };
} = defineMessages({
  404: {
    id: "pages.notfound.hero.subtitle",
    defaultMessage: "Why not try these links instead?",
    description: "Not Found page: Hero Subtitle",
  },
  503: {
    id: "pages.notfound.hero.subtitle.serviceUnavailable",
    defaultMessage:
      "It should only take a couple of hours.{br}Please bear with us while we make the finishing touches. 🪄",
    description: "Not Found page: Hero Subtitle for 503 status",
  },
});

const NotFound: React.FC<NotFoundProps> = ({ status = 404 }) => {
  const intl = useIntl();
  const routesMap = localeRouteHelper[intl.locale];

  const title: any =
    !!titles && intl.formatMessage(titles[status], { br: <br /> });
  const subtitle: any =
    !!subtitles && intl.formatMessage(subtitles[status], { br: <br /> });

  return (
    <Page className={styles.container}>
      <Helmet
        title={intl.formatMessage({
          id: "pages.notfound.page.title",
          description: "Not Found page: Page Title",
          defaultMessage: "Peanut - Find Friends and Support",
        })}
        description={intl.formatMessage({
          id: "pages.notfound.page.description",
          description: "Not Found page: Page Description",
          defaultMessage:
            "Whether you're navigating fertility, pregnancy, motherhood or menopause, Peanut is a safe space to ask questions, find support and connect with other women.",
        })}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Peanut",
            url: "https://www.peanut-app.io",
            sameAs: [
              "https://twitter.com/peanut/",
              "https://www.facebook.com/PeanutAppOfficial/",
              "https://www.instagram.com/peanut/",
              "https://uk.pinterest.com/peanutapp/",
            ],
          })}
        </script>
      </Helmet>
      <PageHeaderBar className={styles.header} />

      <PageSection className={styles.section}>
        <div className={styles.leftPane}>
          <div className={styles.messageContainer}>
            <p className={styles.messageTitle}>{title}</p>
            <p className={styles.messageSubtitle}>{subtitle}</p>
          </div>

          {status === 404 && (
            <div className={styles.redirectLinks}>
              <BubbleLink
                path={routesMap.community()}
                text={intl.formatMessage({
                  id: "pages.notfound.community.title",
                  description: "Not Found page: Community Link",
                  defaultMessage: "What's new on the Community",
                })}
                size="large"
                bgColor="secondaryColor"
              />
              <BubbleLink
                path={routesMap.topicBlogIndex()}
                text={intl.formatMessage({
                  id: "pages.notfound.blog.title",
                  description: "Not Found page: Blog Link",
                  defaultMessage: "What's new on the Blog",
                })}
                size="large"
                bgColor="secondaryColor"
              />
            </div>
          )}
        </div>
        <div className={styles.rightPane}>
          <img src={image} alt="placeholder" />
        </div>
      </PageSection>
      <PageFooter />
    </Page>
  );
};

export default NotFound;
