import useReferralData from "hooks/useReferralData/useReferralData";

function useWebAppLink(path?: string) {
  const { isReferredUser, applyReferralData } = useReferralData();
  const baseLink = `${process.env.REACT_APP_WEB_APP_URL}${path || ""}`;

  return isReferredUser ? applyReferralData(baseLink) : baseLink;
}

export default useWebAppLink;
