import useSWR from 'swr';
import axios from 'helpers/axios';
import defaultSWROptions from 'helpers/defaultSWROptions';
import fetcher from './fetcher';

export interface ConversationMessage {
  sender: string;
  body: string;
}

export interface LatestConversation {
  message_limit: {
    left: number;
    total: number;
  };
  messages: ConversationMessage[];
  rating: number | null;
}

const useChatbotGetMessage = (userId: string) => {
  const { data, error, isLoading } = useSWR<LatestConversation>(
    {
      url:  userId ? `/api/web/${userId}/chat_bot/conversations/latest` : null,
    },
    fetcher(axios),
    defaultSWROptions
  );

  return {
    conversation: data,
    error,
    isLoading,
  };
};

export default useChatbotGetMessage;
