/* eslint-disable import/no-named-as-default */
import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";
import { AuthorSummary } from "types/authorSummary";

export type JSONAuthorSummary = {
  title: string;
  job_title: string;
  status: string;
  slug: string;
  path: string;
  locale: string;
  heading: string;
  meta_description: string;
  meta_title: string;
  summary: string;
  id: string;
  language: string;
  body: string;
  flags: string[];
  name: string;
  email: string;
  phone_number: string;
  photo_url: string;
  canonical_link: string;
  linkedin_url: string;
  personal_url: string;
  workplace_url: string;
  peanut_group_url: string;
  medical_expert: boolean;
  medical_specialities?: string[];
  author_id: string;
};

const transformAuthorSummary = (
  apiAuthorSummary: JSONAuthorSummary
): AuthorSummary => {
  const data = removeEmpty(objectKeysToCamelCase(apiAuthorSummary));

  data.medicalSpecialities = apiAuthorSummary?.medical_specialities?.map(speciality => ({
      id: speciality.toLowerCase(),
      name: speciality,
    }));

  return data;
};

export default transformAuthorSummary;
