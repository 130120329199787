import routeHelper from "i18n/routeHelper";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ChrissyPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(routeHelper.en.communityPost("w8xq-grc", "feeling-shitty"));
  }, []);

  return <></>;
}

export default ChrissyPage;
