import { ReferralDataContext } from "hooks/useReferralData/ReferralDataProvider";
import { useCallback, useContext, useState } from "react";
import Config from "../../config";

const useBranchSdk = () => {
  const [branchData, setBranchData] = useState();
  const [branchError, setBranchError] = useState();
  const {
    data: { branchMatchId },
  } = useContext(ReferralDataContext);

  const windowDefined = typeof window !== "undefined";

  const loadBranchSdk = useCallback(() => {
    if (!windowDefined) return;
    if (window.branch) return;

    // prettier-ignore
    // eslint-disable-next-line
    (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData".split(" "), 0);
  }, [windowDefined]);

  const initBranchSdk = () => {
    loadBranchSdk();

    window.branch.init(Config.tracking.branch_key, {}, (err, data) => {
      setBranchData(data?.data_parsed);
      setBranchError(err);
    });
  };

  const asyncInit = async () => {
    loadBranchSdk();

    return new Promise((resolve, reject) => {
      const options = branchMatchId ? { branch_match_id: branchMatchId } : {};

      window.branch.init(Config.tracking.branch_key, options, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  return {
    data: branchData,
    error: branchError,
    asyncInit,
    initialize: initBranchSdk,
  };
};

export default useBranchSdk;
